import Vue from "vue";

export const EVENTS = {
  APP_READY: "APP_READY",
  IS_ONLINE: "IS_ONLINE",
  SHOW_FLASH: "SHOW_FLASH",
  SHOW_MODAL_FLASH: "SHOW_MODAL_FLASH",
  HIDE_FLASH: "HIDE_FLASH",
  HIDE_MODAL_FLASH: "HIDE_MODAL_FLASH",
  APP_LOADER: "APP_LOADER",
  NEW_MESSAGE: "NEW_MESSAGE",
  CHECK_FOR_UPDATED_DATA: "CHECK_FOR_UPDATED_DATA"
};

const EventBus = new Vue();

export default EventBus;
