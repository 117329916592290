import axios from "globals/services/api";
import { getMessagesFromDiscussionTree } from "globals/utils/utils";

const ChatAPI = {
  getAllUnreadMessages(userId) {
    return axios
      .get(`Api/messages/GetNewMessages/${userId}`)
      .then((data) => {
        return data.messages;
      })
      .catch((err) => {
        return [];
      });
  },
  getThread(userId, threadId) {
    return axios.get(`/Api/messages/threadContent/${threadId}/${userId}`).then((data) => {
      let messages = [];
      if (data && data.DiscussionTree) {
        messages = getMessagesFromDiscussionTree(data.DiscussionTree);
      }
      return {
        thread: data,
        messages
      };
    });
  },

  getThreads(userId) {
    return axios
      .get(`/Api/messages/getThreads/${userId}`)
      .then((data) => {
        return data;
      })
      .catch((err) => {
        return false;
      });
  },
  getThreadLastMessage(threadId) {
    return axios
      .get(`/Api/messages/getLastMessage/${threadId}`)
      .then((data) => {
        return data;
      })
      .catch((err) => {
        return false;
      });
  },
  messageWasViewed(messageId, userId) {
    return axios
      .post(`/Api/messages/MarkAsRead/${userId}/${messageId}`)
      .then((data) => {
        //if 200 ok then it means the message was set to viewed
        return true;
      })
      .catch((err) => {
        return false;
      });
  },
  createMessage(lastMessageId, senderId, message) {
    return axios
      .post(
        "/Api/messages/ReplyToThread",
        {
          IdPreviousMessage: lastMessageId ? lastMessageId : "0",
          IdSender: senderId,
          MessageContent: message
        },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then((data) => {
        let messages = [];
        if (data && data.DiscussionTree) {
          messages = getMessagesFromDiscussionTree(data.DiscussionTree);
        }

        return messages;
      })
      .catch((err) => {
        //  console.log(err)
      });
  },
  createThread({
    senderId,
    threadName = "",
    message = "",
    projectId = null,
    branchId = null,
    departmentId = null,
    receiversId = null
  }) {
    //  console.log(receiversId)
    return axios
      .post(
        "/Api/messages/NewThread",
        {
          SenderId: senderId,
          DiscussionName: threadName,
          IdProjet: projectId ? projectId : 0, //default to 0
          IdEtablissement: branchId ? branchId : 0,
          IdDepartement: departmentId ? departmentId : 0,
          Recipients: receiversId ? receiversId.join(",") : null, //array to string ID,ID2,ID3
          NewMessageText: message
        },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then((data) => {
        //  console.log(data)
        return data;
      })
      .catch((err) => {
        //console.log(err)
        throw err;
      });
  }
};

export default ChatAPI;
