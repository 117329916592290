export const COMPANY_LOGIN = "COMPANY_LOGIN";

export const COMPANY_LOGOUT = "COMPANY_LOGOUT";

export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";

//VUEx persist
export const RESTORE_MUTATION = "RESTORE_MUTATION";

export const SAVE_LAST_PUNCH = "SAVE_LAST_PUNCH";
export const GET_PROJECTS = "GET_PROJECTS";

export const GET_COMPANIES = "GET_COMPANIES";
export const CREATE_PROJECT = "CREATE_PROJECT";
export const GET_ACTIVITIES = "GET_ACTIVITIES";
export const GET_OPERATIONS = "GET_OPERATIONS";
export const LOAD_USER_DATA = "LOAD_USER_DATA";
export const GET_USER_PARAMETERS = "GET_USER_PARAMETERS";
export const GET_DEFAULT_DEPARTMENTS = "GET_DEFAULT_DEPARTMENTS";
export const GET_DEPARTMENTS = "GET_DEPARTMENTS";
export const GET_DEPARTMENTS_BY_GROUP = "GET_DEPARTMENTS_BY_GROUP";
export const GET_DEPARTMENT_GROUPS = "GET_DEPARTMENT_GROUPS";
export const GET_BRANCHS = "GET_BRANCHS";
export const ROUTE_CHANGED = "ROUTE_CHANGED";
export const ERROR_MESSAGE = "ERROR_MESSAGE";
export const LOAD_LOCATION = "LOAD_LOCATION";
export const LAST_FETCH_DATE = "LAST_FETCH_DATE";
export const LAST_COMPANY_FETCH_DATE = "LAST_COMPANY_FETCH_DATE";

export const GET_EMPLOYEES = "GET_EMPLOYEES";
export const GET_UNREAD_MESSAGES = "GET_UNREAD_MESSAGES";

export const SET_LANG = "SET_LANG";

export const GET_ALL_COMPANY_DATA = "GET_ALL_COMPANY_DATA";
