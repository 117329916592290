<template>
  <div
    class="message"
    :class="{ '-from-me': fromMe }">
    <div class="mt-2 relative">
      <span v-if="wasViewedByRecipient">
        <i class="fa fa-eye text-light-grey"></i>
      </span>
      <div class="profile-pic -small">
        <inline-icon name="user" />
      </div>
    </div>

    <div class="message__body">
      <h5
        class="message__body__from"
        v-if="sender">
        {{ sender.nomEmploye | titleCase }}
      </h5>
      <p class="message__body__content">
        {{ message.MessageContent }}
      </p>
    </div>
  </div>
</template>
<script>
import * as types from "mobile/store/types";
import InlineIcon from "globals/components/InlineIcon";
import moment from "moment";

export default {
  name: "message",
  components: { InlineIcon },
  props: {
    message: {
      type: Object,
      required: true
    },
    recipientId: {
      type: String,
      required: false
    }
  },
  data() {
    return {};
  },
  created() {
    this.checkWasViewed();
  },
  methods: {
    checkWasViewed() {
      //check if not viewed before sending
      let wasViewed = false;
      if (this.message.ReadBy.length > 0) {
        wasViewed = this.message.ReadBy.some((r) => r.RecipientId === this.$store.getters.userUniqueId);
      }
      if (!wasViewed) {
        this.$store.dispatch(types.MESSAGE_WAS_VIEWED, {
          userId: this.$store.getters.userUniqueId,
          messageId: this.message.IdAuto
        });
      }
    }
  },
  computed: {
    fromMe() {
      return this.message.IdSender === this.$store.getters.userUniqueId;
    },
    sender() {
      if (this.fromMe) {
        return {
          idEmploye: this.$store.getters.userUniqueId,
          noEmploye: this.$store.getters.UserId,
          nomEmploye: "Moi" //this.$store.state.auth.user.Name
        };
      }
      return this.$store.state.data.employees.find((e) => e.idEmploye === this.message.IdSender) || null;
    },
    wasViewedByRecipient() {
      if (!this.fromMe) return false;
      if (!this.recipientId) return false;
      let wasViewed = false;
      if (this.message.ReadBy.length > 0) {
        wasViewed = this.message.ReadBy.some((r) => r.RecipientId === this.recipientId);
      }
      return wasViewed;
    },
    time() {
      return moment(this.message.CreateTime).format("L");
    }
  }
};
</script>
