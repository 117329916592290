import { register } from "register-service-worker";
import EventBus, { EVENTS } from "globals/utils/eventbus";
import { STRINGS } from "globals/utils/constant";

// eslint-disable-next-line no-console
console.log(process.env.BASE_URL);
if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      // eslint-disable-next-line no-console
      console.log(
        "App is being served from cache by a service worker.\n" + "For more details, visit https://goo.gl/AFskqB"
      );
    },
    cached() {
      // eslint-disable-next-line no-console
      console.log("Content has been cached for offline use.");
    },
    updated(registration) {
      // eslint-disable-next-line no-console
      console.log("Content has been updated");

      EventBus.$emit(EVENTS.SHOW_FLASH, {
        message: STRINGS(window.vueStore.state.auth.lang || "fr").PLEASE_REFRESH,
        color: "red",
        textColor: "light"
      });

      registration.unregister();
      caches.delete("views");
      caches.delete("src");

      try {
        //tell the service worker to refresh next time
        window.navigator.serviceWorker.controller.postMessage({ type: "SKIP_WAITING" });
      } catch (e) {
        // eslint-disable-next-line no-console
        //console.log(e)
      }
    },
    offline() {
      // eslint-disable-next-line no-console
      console.log("No internet connection found. App is running in offline mode.");
    },
    error(error) {
      // eslint-disable-next-line no-console
      console.error("Error during service worker registration:", error);
    }
  });
}
