export const AJAX_ERROR = "AJAX_ERROR";
export const LOGIN = "LOGIN";

export const ERROR_MESSAGE = "ERROR_MESSAGE";

export const PUNCH = "PUNCH";

export const ADD_TO_OFFLINE_PUNCH = "ADD_TO_OFFLINE_PUNCH";
export const MAKE_ALL_OFFLINE_PUNCHS = "MAKE_ALL_OFFLINE_PUNCHS";

export const GET_SITE_PATH = "GET_SITE_PATH";

export const SET_IS_ONLINE = "SET_IS_ONLINE";
