import axios, { setHeaders } from "globals/services/api";

const AuthAPI = {
  setBearer(token) {
    setHeaders({
      Authorization: `Bearer ${token}`
    });
  },
  loginUser(companyId) {
    let formData = new URLSearchParams();

    formData.append("username", companyId);
    formData.append("password", companyId);
    formData.append("grant_type", "password");

    return axios.post("/oauth/token", formData);
  },
  getUserParams(nip) {
    return axios.get("/Parameters/User", { params: { NIP: nip } }).then((data) => {
      return data;
    });
  },
  resetBearer() {
    setHeaders({
      Authorization: ""
    });
  },
  setUserMobileDeviceId(userNip, userMobileDeviceId) {
    return axios.get(`api/employees/setMobileDeviceId/${userNip}/${userMobileDeviceId}`).then((data) => {
      return data;
    });
  }
};

export default AuthAPI;
