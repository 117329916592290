<template>
  <section
    id="index"
    class="h-full px-2">
    <h1 class="text-center text-light my-4">{{ STRINGS.GREETING }} {{ $store.getters.fullName }}</h1>
    <div class="flex flex-col justify-between items-center w-full h-full space-y-4">
      <router-link
        v-if="showPunchBtn"
        :to="ROUTES.TIMER"
        class="btn w-full my-0">
        {{ STRINGS.INDEX_BTN_PUNCH }}
      </router-link>

      <a
        v-if="parameters && parameters.displayWorkedHours"
        @click.prevent="goToExternalLink(parameters.hoursWorkedLink)"
        class="btn w-full my-0">
        {{ STRINGS.INDEX_BTN_HOURS }}
      </a>
      <a
        v-if="parameters && parameters.schedulesUsed"
        @click.prevent="goToExternalLink(parameters.scheduleLink)"
        class="btn w-full my-0">
        {{ STRINGS.INDEX_BTN_SCHEDULE }}
      </a>
      <a
        v-if="parameters && parameters.banksLink"
        @click.prevent="goToExternalLink(parameters.banksLink)"
        class="btn w-full my-0">
        {{ STRINGS.INDEX_BTN_BANK }}
      </a>
      <router-link
        v-if="parameters && parameters.mobileChatActive"
        :to="ROUTES.MESSAGES"
        class="btn w-full my-0">
        {{ STRINGS.INDEX_BTN_CHAT }}
      </router-link>
      <a
        v-if="parameters && parameters.displayAbsenceRequest"
        @click.prevent="goToExternalLink(parameters.absenceRequestLink)"
        class="btn w-full my-0">
        {{ STRINGS.INDEX_BTN_ABSENCE }}
      </a>
    </div>
  </section>
</template>

<script>
import EventBus, { EVENTS } from "globals/utils/eventbus";
import * as types from "mobile/store/types";
import { ROUTES } from "mobile/router/routes";
import { ALWAYS_REFETCH_DATA_AFTER_HOURS } from "globals/utils/constant";
import moment from "moment";
import DataAPI from "globals/services/DataAPI";

export default {
  name: "index",
  components: {},
  data() {
    return {};
  },
  created() {
    //return back, if not login
    if (!this.$store.getters.isLogin) {
      this.$router.replace(ROUTES.LOGIN);
      return;
    }
  },
  computed: {
    parameters() {
      return this.$store.state.data.parameters;
    },
    showPunchBtn() {
      if (!this.$store.state.auth.user) return false;
      if (!this.parameters) return false;
      return this.parameters.AppMobileUsed && this.$store.state.auth.user.MobilePunch;
    }
  },
  methods: {
    goToExternalLink(link) {
      let url = this.$store.state.data.sitePath + link;

      //if already contain query, use &
      let queryStart = "?";
      if (url.includes("?")) {
        queryStart = "&";
      }

      url += `${queryStart}QLangue=fr&pinOverride=${this.$store.getters.userNIP}&noEmployeOverride=${this.$store.getters.userId}`;

      //open new page
      open(url, "_blank");
    }
  }
};
</script>
