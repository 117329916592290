import EventBus, { EVENTS } from "globals/utils/eventbus";
import * as globalTypes from "globals/store/types";

export default function createOfflinePunchPlugin() {
  const INCLUDES = [globalTypes.PUNCH];
  return (store) => {
    store.subscribeAction((action) => {
      if (!INCLUDES.includes(action.type)) return;
      if (store.state.general.isOnline) return;
      store.dispatch(globalTypes.ADD_TO_OFFLINE_PUNCH, action.payload);
    });

    EventBus.$on(EVENTS.IS_ONLINE, (_isOnline) => {
      if (_isOnline) {
        store.dispatch(globalTypes.MAKE_ALL_OFFLINE_PUNCHS);
      }
    });
  };
}
