<template>
  <component
    :is="tag"
    class="text-center text-light"
    v-html="now"></component>
</template>

<script>
import moment from "moment";

export default {
  name: "Time",
  props: {
    tag: {
      type: String,
      default: "h2"
    }
  },
  data() {
    return {
      now: ""
    };
  },
  created() {
    this.setNow = this.setNow.bind(this);
    this.setNow();

    const EVERY_SECOND_MS = 100; //1sec * 60
    this.intervalId = setInterval(this.setNow, EVERY_SECOND_MS);
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  methods: {
    setNow() {
      this.now = moment().format("LL HH:mm:ss ");
    }
  }
};
</script>
