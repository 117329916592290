<template>
  <div class="form flex flex-col justify-between h-full timer-form">
    <div class="">
      <div
        class="input-container -select"
        v-if="parameters && parameters.mobilePunchUseCompanies">
        <label
          :for="`companies-${_uid}`"
          class="pl-12">
          {{ selectStrings.company }}
        </label>
        <div>
          <div
            class="select-container"
            @click.stop.prevent="openModal('companies')">
            <span class="search-icon">
              <span class="search-icon-item">
                <i class="fa fa-search"></i>
              </span>
            </span>
            <ModalSelect
              v-if="modals.companies"
              @close="closeModal"
              @select="select"
              :value="companyId"
              :options="companiesOptions"
              name="companies"
              valueName="companyId" />

            <div class="fake-select">
              <span v-if="companySelected">{{ companySelected.nomCie }}</span>
              <span v-else>{{ STRINGS.PLEASE_SELECT_OPTION }}</span>
            </div>
          </div>
        </div>
        <p
          class="error"
          v-if="validationErrors.projectId">
          {{ validationErrors.projectId }}
        </p>
      </div>
      <div
        class="input-container -select"
        v-if="parameters.ProjectUsed">
        <label
          :for="`projects-${_uid}`"
          class="pl-12">
          {{ selectStrings.project }}
        </label>
        <div
          :class="{
            'flex flex-row justify-center items-center': showScanner
          }">
          <button
            type="button"
            v-if="showScanner"
            class="mr-6"
            :class="{
              'opacity-50': barcodeNameActive !== 'projects'
            }"
            ref="_scannerBtnProject"
            @click.prevent="onClickBarcode('projects')">
            <inline-icon
              name="barcode"
              class="w-12 h-auto block text-light" />
          </button>
          <div
            class="select-container"
            :class="{
              'w-full ml-6': showScanner
            }"
            @click.stop.prevent="openModal('projects')">
            <span class="search-icon">
              <a
                class="search-icon-item"
                @click.stop.prevent="projectInfoClick"
                target="_blank"
                v-if="showMoreProjectInfoURL">
                <i class="fa fa-question"></i>
              </a>
              <span
                v-else
                class="search-icon-item">
                <i class="fa fa-search"></i>
              </span>
            </span>
            <ModalSelect
              v-if="modals.projects"
              @close="closeModal"
              @select="select"
              :value="projectId"
              :options="projectsOptions"
              name="projects"
              valueName="projectId" />

            <div class="fake-select">
              <span v-if="projectSelected">{{ projectSelected.Name }}</span>
              <span v-else>{{ STRINGS.PLEASE_SELECT_OPTION }}</span>
            </div>
          </div>
        </div>
        <p
          class="error"
          v-if="validationErrors.projectId">
          {{ validationErrors.projectId }}
        </p>
      </div>
      <div
        class="input-container -select"
        v-if="parameters.ActivitiesUsed">
        <label
          :for="`activities-${_uid}`"
          class="pl-12">
          {{ selectStrings.activity }}
        </label>
        <div
          :class="{
            'flex flex-row justify-center items-center': showScanner
          }">
          <button
            type="button"
            v-if="showScanner"
            class="mr-6"
            :class="{
              'opacity-50': barcodeNameActive !== 'activities'
            }"
            ref="_scannerBtnActivity"
            @click.prevent="onClickBarcode('activities')">
            <inline-icon
              name="barcode"
              class="w-12 h-auto block text-light" />
          </button>
          <div
            class="select-container"
            :class="{
              'w-full ml-6': showScanner
            }"
            @click.stop.prevent="openModal('activities')">
            <span class="search-icon">
              <span class="search-icon-item">
                <i class="fa fa-search"></i>
              </span>
            </span>

            <ModalSelect
              v-if="modals.activities"
              @close="closeModal"
              @select="select"
              :value="activityId"
              :options="activitiesOptions"
              name="activities"
              valueName="activityId" />
            <div class="fake-select">
              <span v-if="activitySelected">{{ activitySelected.Name }}</span>
              <span v-else>{{ STRINGS.PLEASE_SELECT_OPTION }}</span>
            </div>
          </div>
        </div>
        <p
          class="error"
          v-if="validationErrors.activityId">
          {{ validationErrors.activityId }}
        </p>
      </div>
      <div
        class="input-container -select"
        v-if="parameters.OperationsUsed">
        <label
          :for="`operations-${_uid}`"
          class="pl-12">
          {{ selectStrings.operation }}
        </label>
        <div
          :class="{
            'flex flex-row justify-center items-center': showScanner
          }">
          <button
            type="button"
            v-if="showScanner"
            class="mr-6"
            :class="{
              'opacity-50': barcodeNameActive !== 'operations'
            }"
            ref="_scannerBtnOperation"
            @click.prevent="onClickBarcode('operations')">
            <inline-icon
              name="barcode"
              class="w-12 h-auto block text-light" />
          </button>
          <div
            class="select-container"
            :class="{
              'w-full ml-6': showScanner
            }"
            @click.stop.prevent="openModal('operations')">
            <span class="search-icon">
              <span class="search-icon-item">
                <i class="fa fa-search"></i>
              </span>
            </span>
            <ModalSelect
              v-if="modals.operations"
              @close="closeModal"
              @select="select"
              :value="operationId"
              :options="operationsOptions"
              name="operations"
              valueName="operationId" />

            <div class="fake-select">
              <span v-if="operationSelected">{{ operationSelected.Name }}</span>
              <span v-else>{{ STRINGS.PLEASE_SELECT_OPTION }}</span>
            </div>
          </div>
        </div>
        <p
          class="error"
          v-if="validationErrors.operationId">
          {{ validationErrors.operationId }}
        </p>
      </div>
      <div
        class="input-container -select"
        v-if="parameters.BranchUsed">
        <label :for="`branchs-${_uid}`">
          {{ selectStrings.branch }}
        </label>
        <div
          class="select-container"
          @click.stop.prevent="openModal('branchs')">
          <span class="search-icon">
            <span class="search-icon-item">
              <i class="fa fa-search"></i>
            </span>
          </span>
          <ModalSelect
            v-if="modals.branchs"
            @close="closeModal"
            @select="select"
            :value="branchId"
            :options="branchsOptions"
            name="branchs"
            valueName="branchId" />

          <div class="fake-select">
            <span v-if="branchSelected">{{ branchSelected.Name }}</span>
            <span v-else>{{ STRINGS.PLEASE_SELECT_OPTION }}</span>
          </div>
        </div>
        <p
          class="error"
          v-if="validationErrors.branchId">
          {{ validationErrors.branchId }}
        </p>
      </div>
      <div
        class="input-container -select"
        v-if="parameters.DepartmentsUsed">
        <label :for="`departments-${_uid}`">
          {{ selectStrings.department }}
        </label>
        <div
          class="select-container"
          @click.stop.prevent="openModal('departments')">
          <span class="search-icon">
            <span class="search-icon-item">
              <i class="fa fa-search"></i>
            </span>
          </span>
          <ModalSelect
            v-if="modals.departments"
            @close="closeModal"
            @select="select"
            :value="departmentId"
            :options="departmentsOptions"
            name="departments"
            valueName="departmentId" />

          <div class="fake-select">
            <span v-if="departmentSelected">{{ departmentSelected.Name }}</span>
            <span v-else>{{ STRINGS.PLEASE_SELECT_OPTION }}</span>
          </div>
        </div>
        <p
          class="error"
          v-if="validationErrors.departmentId">
          {{ validationErrors.departmentId }}
        </p>
      </div>
      <div
        class="input-container -select"
        v-if="parameters.useDepartmentGroups">
        <label :for="`department-groups-${_uid}`">
          {{ selectStrings.departmentGroup }}
        </label>
        <div
          class="select-container"
          @click.stop.prevent="openModal('departmentGroups')">
          <span class="search-icon">
            <span class="search-icon-item">
              <i class="fa fa-search"></i>
            </span>
          </span>
          <ModalSelect
            v-if="modals.departmentGroups"
            @close="closeModal"
            @select="select"
            :value="departmentGroupId"
            :options="departmentGroupsOptions"
            name="departmentGroups"
            valueName="departmentGroupId" />

          <div class="fake-select">
            <span v-if="departmentGroupSelected">{{ departmentGroupSelected.Name }}</span>
            <span v-else>{{ STRINGS.PLEASE_SELECT_OPTION }}</span>
          </div>
        </div>
        <p
          class="error"
          v-if="validationErrors.departmentGroupId">
          {{ validationErrors.departmentGroupId }}
        </p>
      </div>
    </div>

    <div class="row mt-4 relative">
      <div class="red-gradient-border-middle"></div>

      <BtnEffect
        :disabled="!canPunchIn"
        @click="punch(PUNCH_TYPES.IN)"
        class="w-1/2">
        <span v-if="transferAllowed">
          {{ STRINGS.BUTTON_TRANSFER }}
        </span>
        <span v-else>
          {{ STRINGS.BUTTON_START_TIMER }}
        </span>
      </BtnEffect>

      <BtnEffect
        :disabled="!canPunchOut"
        @click="punch(PUNCH_TYPES.OUT)"
        class="w-1/2">
        {{ STRINGS.BUTTON_END_TIMER }}
      </BtnEffect>
      <div
        class="red-gradient-border"
        v-if="parameters.LunchUsed"></div>
      <BtnEffect
        :disabled="!canPunchInLunch"
        @click="punch(PUNCH_TYPES.IN_LUNCH)"
        class="w-1/2"
        v-if="parameters.LunchUsed">
        {{ STRINGS.BUTTON_START_LUNCH }}
      </BtnEffect>

      <BtnEffect
        :disabled="!canPunchOutLunch"
        @click="punch(PUNCH_TYPES.OUT_LUNCH)"
        class="w-1/2"
        v-if="parameters.LunchUsed">
        {{ STRINGS.BUTTON_END_LUNCH }}
      </BtnEffect>
    </div>

    <div class="input-container light">
      <label :for="`note-${_uid}`">{{ STRINGS.NOTE }}</label>
      <input
        :id="`note-${_uid}`"
        type="text"
        name=""
        v-model="note"
        class="" />
      <p
        class="error"
        v-if="errorNote">
        {{ errorNote }}
      </p>
    </div>
  </div>
</template>
<script>
import { isEmpty, sortAlphabetic, tryAsyncFunc } from "globals/utils/utils";
import moment from "moment";
import BtnEffect from "globals/components/BtnEffect";
import { WORK_TYPES, PUNCH_TYPES } from "globals/utils/constant";
import ModalSelect from "globals/components/modals/ModalSelect";
import merge from "lodash-es/merge";
import formMixin from "globals/config/formMixin";
import InlineIcon from "globals/components/InlineIcon.vue";
import gsap from "gsap";
import * as types from "ipad/store/types";
import EventBus, { EVENTS } from "globals/utils/eventbus";

export default {
  name: "timer-form",
  components: { InlineIcon, ModalSelect, BtnEffect },
  mixins: [formMixin],
  props: {
    withScanner: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      note: "",
      errorNote: null,
      currentBarcode: "",

      isSendingPunches: false,

      barcodeNameActive: ""
    };
  },
  watch: {
    //dont init to projects right away, since sometimes it will not be used
    "parameters.ProjectUsed": {
      handler: function (nextValue) {
        //if used, use projects
        this.barcodeNameActive = nextValue ? "projects" : "";
      },
      immediate: true
    },
    withScanner(nextValue) {
      //todo handle if we use company
      if (nextValue) {
        this.activateBarcodeScanner();
      } else {
        this.deactivateBarcodeScanner();
      }
    },
    "$store.getters.isLogin"() {
      this.$nextTick(() => {
        if (this.$store.getters.isLogin) {
          this.setDefaultValues();
        }
      });
    }
  },
  created() {
    this.handleBarcodeScannerEvent = this.handleBarcodeScannerEvent.bind(this);
    this.handleFinalBarcodeScanned = this.handleFinalBarcodeScanned.bind(this);

    this.barcodeScannerTimeoutId = null;

    //todo handle if we use company
    if (this.withScanner) {
      this.activateBarcodeScanner();
    } else {
      this.deactivateBarcodeScanner();
    }

    if (this.$store.getters.isLogin) {
      this.setDefaultValues();
    }

    //check for new data right now
    EventBus.$emit(EVENTS.CHECK_FOR_UPDATED_DATA); //make sure we get latest data if needed
  },
  beforeDestroy() {
    clearTimeout(this.barcodeScannerTimeoutId); //clear timer
  },
  computed: {
    showScanner() {
      if (!this.withScanner) return false;
      if (!this.parameters) return false;
      if (this.parameters.mobilePunchUseCompanies) {
        return !!this.companyId; //only show scanner if we select company
      }
      return true;
    },
    lastPunch() {
      return this.$store.state.data.lastPunch;
    },
    transferAllowed() {
      //return true so we dont block user if its his first punch
      if (!this.lastPunch) {
        return false;
      }

      //only accept IN and after lunch
      if (this.lastPunch.CheckType === PUNCH_TYPES.IN || this.lastPunch.CheckType === PUNCH_TYPES.OUT_LUNCH) {
        if (this.parameters.ProjectUsed && parseInt(this.lastPunch.idProjet) !== this.projectId) {
          return true;
        }

        if (this.parameters.DepartmentsUsed && parseInt(this.lastPunch.WorkType) !== this.departmentId) {
          return true;
        }

        if (
          this.parameters.useDepartmentGroups &&
          parseInt(this.lastPunch.groupeDepartement) !== this.departmentGroupId
        ) {
          return true;
        }

        if (this.parameters.BranchUsed && parseInt(this.lastPunch.Checked) !== this.branchId) {
          return true;
        }

        if (this.parameters.ActivitiesUsed && parseInt(this.lastPunch.idActivite) !== this.activityId) {
          return true;
        }
        if (this.parameters.OperationsUsed && parseInt(this.lastPunch.idOperation) !== this.operationId) {
          return true;
        }
      }

      return false;
    },
    canPunchIn() {
      //disabled btn on already sending
      if (this.isSendingPunches) {
        return false;
      }

      //return true so we dont block user if its his first punch
      if (!this.lastPunch) {
        return true;
      }

      //in some case it will be null, it can puch if its the case
      if (!this.lastPunch.CheckType) {
        return true;
      }

      //can only use punch btns other than IN if he is IN
      if (this.lastPunch.CheckType === PUNCH_TYPES.OUT) {
        return true;
      }

      //if the last punch is different from all the punch type, still accept a punch in
      if (!Object.values(PUNCH_TYPES).includes(this.lastPunch.CheckType)) {
        return true;
      }

      //transfer allow
      if (this.transferAllowed) {
        return true;
      }

      return false;
    },
    canPunchOut() {
      //disabled btn on already sending
      if (this.isSendingPunches) {
        return false;
      }

      //return true so we dont block user if its his first punch
      if (!this.lastPunch) {
        return true;
      }
      //can only use punch btns other than IN if he is IN
      if (this.lastPunch.CheckType === PUNCH_TYPES.IN || this.lastPunch.CheckType === PUNCH_TYPES.OUT_LUNCH) {
        return true;
      }

      return false;
    },
    canPunchInLunch() {
      //disabled btn on already sending
      if (this.isSendingPunches) {
        return false;
      }

      //return true so we dont block user if its his first punch
      if (!this.lastPunch) {
        return true;
      }
      //can only use punch btns other than IN if he is IN
      if (this.lastPunch.CheckType === PUNCH_TYPES.IN) {
        return true;
      }

      return false;
    },
    canPunchOutLunch() {
      //disabled btn on already sending
      if (this.isSendingPunches) {
        return false;
      }

      //return true so we dont block user if its his first punch
      if (!this.lastPunch) {
        return true;
      }
      //can only punch out of lunch if he started it
      if (this.lastPunch.CheckType === PUNCH_TYPES.IN_LUNCH) {
        return true;
      }

      return false;
    }
  },
  methods: {
    onClickBarcode(name) {
      this.barcodeNameActive = name;

      //unselect id under them so make sure we dont have random value when scan
      if (name === "projects") {
        this.activityId = null;
        this.operationId = null;
      } else if (name === "activities") {
        this.operationId = null;
      }

      //always reset
      this.branchId = null;
      this.departmentId = null;
      this.departmentGroupId = null;
    },
    activateBarcodeScanner() {
      this.deactivateBarcodeScanner(); //make sure to deactivate before so we dont get multiple listener

      document.addEventListener("keydown", this.handleBarcodeScannerEvent);
    },
    deactivateBarcodeScanner() {
      document.removeEventListener("keydown", this.handleBarcodeScannerEvent);
    },
    handleBarcodeScannerEvent(e) {
      if (!this.showScanner) return;
      if (!this.barcodeNameActive) return; //only check input when is active
      const textInput = e.key || String.fromCharCode(e.keyCode);
      //const targetName = e.target.localName;

      //not a input based input, only 1 char, not enter and exist
      if (textInput && textInput.length === 1 && e.keyCode !== 13) {
        this.currentBarcode += textInput;
      }

      //the scanner will trigger an Enter key press at the end
      if (e.keyCode === 13) {
        this.handleFinalBarcodeScanned(this.currentBarcode);
        this.currentBarcode = ""; //reset so new barcode can be enter
      }

      //after .75 seconds, reset barcode since a barcode scanner will not wait...
      clearTimeout(this.barcodeScannerTimeoutId); //clear old timer
      this.barcodeScannerTimeoutId = setTimeout(() => {
        this.currentBarcode = "";
      }, 750);
    },
    async handleFinalBarcodeScanned(barcode) {
      let currentBarcodeName = this.barcodeNameActive;
      this.closeModal({ name: currentBarcodeName }); //this.barcodeNameActive will be reset here
      await this.$nextTick();

      let _btn = null;
      if (currentBarcodeName === "projects") {
        _btn = this.$refs._scannerBtnProject;
      } else if (currentBarcodeName === "activities") {
        _btn = this.$refs._scannerBtnActivity;
      } else if (currentBarcodeName === "operations") {
        _btn = this.$refs._scannerBtnOperation;
      }

      if (_btn) {
        gsap.set(_btn, { scale: 1 });
        gsap.to(_btn, {
          scale: 1.35,
          duration: 0.2,
          onComplete: () => {
            gsap.to(_btn, { scale: 1, duration: 0.2 });
          }
        });
      }

      if (!barcode) return; //no barcode...

      barcode = barcode.toString().toLowerCase();

      if (currentBarcodeName === "projects") {
        let projectFromBarcode = this.$store.state.data.projects.find((project) => {
          return project.No.toString().toLowerCase() === barcode;
        });

        if (!projectFromBarcode) {
          const [res, err] = await tryAsyncFunc(this.$store.dispatch)(types.CREATE_PROJECT, barcode);

          EventBus.$emit(EVENTS.SHOW_FLASH, {
            message: this.STRINGS.NEW_PROJECT_CREATED,
            color: "green",
            textColor: "light"
          });

          //try again, if not fail
          projectFromBarcode = this.$store.state.data.projects.find((project) => {
            return project.No.toString().toLowerCase() === barcode;
          });
          if (!projectFromBarcode) {
            return;
          }
        }

        //select project
        this.projectId = projectFromBarcode.ID; //will trigger selectFirstItemInSelect by default
      } else if (currentBarcodeName === "activities") {
        //get complete data from options
        let optionsValid = this.activitiesOptions.map((a) => {
          return this.$store.state.data.activities.find((activity) => {
            return activity.ID === a.value;
          });
        });

        let activityFromBarcode = optionsValid.find((a) => {
          if (!a) return false;
          return a.No.toString().toLowerCase() === barcode;
        });
        this.activityId = activityFromBarcode ? activityFromBarcode.ID : null;

        if (!activityFromBarcode) {
          EventBus.$emit(EVENTS.SHOW_FLASH, {
            message: this.STRINGS.BARCODE_INVALID,
            color: "red",
            textColor: "light"
          });
        }
      } else if (currentBarcodeName === "operations") {
        //get complete data from options
        let optionsValid = this.operationsOptions.map((o) => {
          return this.$store.state.data.operations.find((operation) => {
            return operation.ID === o.value;
          });
        });

        let operationFromBarcode = optionsValid.find((o) => {
          if (!o) return false;
          return o.No.toString().toLowerCase() === barcode;
        });
        this.operationId = operationFromBarcode ? operationFromBarcode.ID : null;

        if (!operationFromBarcode) {
          EventBus.$emit(EVENTS.SHOW_FLASH, {
            message: this.STRINGS.BARCODE_INVALID,
            color: "red",
            textColor: "light"
          });
        }
      }
    },
    getPunches(punchType) {
      let punches = []; //punches we gonna send to the server

      //emit data for server
      //need to change the name of values so server can get it
      let punchData = {
        Userid: this.$store.getters.userId.toString(),
        idProjet: !isEmpty(this.projectId) ? this.projectId.toString() : null, //Project
        idActivite: !isEmpty(this.activityId) ? this.activityId.toString() : null, //Activity
        idOperation: !isEmpty(this.operationId) ? this.operationId.toString() : null, //Operation
        Note: this.note,
        // Sensorid: 'mobile',//server
        CheckTime: moment().format("YYYY-MM-DD HH:mm:ss.SSS"), //Checktime
        CheckType: punchType,

        WorkType: WORK_TYPES.NORMAL
      };

      //If projects
      if (this.parameters.ProjectUsed) {
        punchData.Checked = "0"; //Default
      } else {
        if (this.parameters.BranchUsed) {
          punchData.Checked = !isEmpty(this.branchId) ? this.branchId.toString() : null; //branch
        }
      }

      if (this.parameters.DepartmentsUsed) {
        punchData.WorkType = !isEmpty(this.departmentId) ? this.departmentId.toString() : null; //department
      }

      if (this.parameters.useDepartmentGroups) {
        //needs to be integer
        punchData.groupeDepartement = !isEmpty(this.departmentGroupId) ? this.departmentGroupId : null; //department
      }

      //TRANSFER CODE
      if (!isEmpty(this.lastPunch)) {
        //if its the same work type, that mean we need to do a transfer because we cant have same work type
        if (!isEmpty(this.lastPunch.CheckType)) {
          //only transfer on IN
          //must be same check in as lastPunch
          if (punchData.CheckType === PUNCH_TYPES.IN) {
            if (this.transferAllowed) {
              //We need to do a transfer now
              let transferPunch = merge({}, punchData);

              //set data punch to transfer
              transferPunch.WorkType = WORK_TYPES.TRANSFER;
              transferPunch.CheckType = PUNCH_TYPES.OUT;

              //we need to add 1 seconds so the out is after the transfer punch
              punchData.CheckTime = moment(punchData.CheckTime).add(1, "seconds").format("YYYY-MM-DD HH:mm:ss.SSS");

              //Add punches to array so we send it to the server
              punches.push(transferPunch);
            }
          }
        }
      }

      //place our punch in the array
      punches.push(punchData);

      return punches;
    },
    punch(punchType) {
      this.isSendingPunches = true;
      this.validationErrors = {}; //reset

      //validate data
      this.validationErrors = this.validatePunchData();
      //must be valid data
      if (!isEmpty(this.validationErrors)) {
        this.isSendingPunches = false;
        return;
      }

      const punches = this.getPunches(punchType);
      //send to parent component, Timer.vue in this case
      this.$emit("submit", punches);

      //after submit, reset some variables
      this.$nextTick(() => {
        this.note = "";
        this.isSendingPunches = false;
      });
    }
  }
};
</script>
