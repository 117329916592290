<template>
  <LoadingContainer :is-loading="isLoading">
    <section class="messages flex-col justify-center items-center">
      <div class="messages__header">
        <h3 class="text-center text-light">
          {{ STRINGS.MESSAGES }}
        </h3>
        <div class="sub-header m-auto container pb-2">
          <form class="search-header mt-2 p-1">
            <button
              type="button"
              class="search-btn">
              <img
                :src="searchIcon"
                class="ml-1 w-4"
                alt="info-punch-search" />
            </button>
            <input
              type="text"
              v-model="searchValue" />
          </form>
        </div>
      </div>

      <div class="messages__area">
        <pull-to
          :top-load-method="refresh"
          :top-config="TOP_DEFAULT_CONFIG"
          :bottom-config="TOP_DEFAULT_CONFIG">
          <thread
            v-for="thread in filteredThreads"
            :key="thread.IdAuto"
            :thread="thread"></thread>
        </pull-to>
      </div>

      <router-link
        :to="ROUTES.CREATE_THREAD"
        class="btn--full text-center justify-center messages__btn"
        v-if="canCreateThread">
        {{ STRINGS.CREATE_THREAD }}
      </router-link>
    </section>
  </LoadingContainer>
</template>

<script>
import deleteIcon from "globals/assets/img/delete-cross.png";
import searchIcon from "globals/assets/img/search.png";
import Vue from "vue";
import * as types from "mobile/store/types";
import Thread from "mobile/components/chat/Thread";
import LoadingContainer from "globals/components/LoadingContainer";
import PullTo from "vue-pull-to";
import { THREAD_NAME_TYPES } from "globals/utils/constant";
import { ROUTES } from "mobile/router/routes";
import EventBus, { EVENTS } from "globals/utils/eventbus";

export default {
  name: "messages",
  components: { LoadingContainer, Thread, PullTo },
  data() {
    return {
      deleteIcon: deleteIcon,
      searchIcon: searchIcon,

      searchValue: "",
      messages: [],

      canCreateThread: true,

      isLoading: true
    };
  },
  created() {
    this.TOP_DEFAULT_CONFIG = {
      pullText: "Reload", // The text is displayed when you pull down
      triggerText: "Reloading", // The text that appears when the trigger distance is pulled down
      loadingText: "Loading...", // The text in the load
      doneText: "", // Load the finished text
      failText: "Error" // Load failed text
    };

    if (!this.$store.state.data.parameters.mobileChatActive) {
      this.$router.replace(ROUTES.INDEX);
      return;
    }

    this.$store.dispatch(types.GET_EMPLOYEES).then(() => {
      this.isLoading = false;
    });
  },
  computed: {
    threadsWithName() {
      return this.$store.state.data.threads.map((t) => {
        return {
          ...t,
          _threadName: this.$store.getters.getThreadName(t)
        };
      });
    },
    filteredThreads() {
      let searchValue = this.searchValue.toLowerCase();
      return this.threadsWithName.filter((t) => {
        let threadName =
          t._threadName.type === THREAD_NAME_TYPES.RECIPIENTS
            ? t._threadName.name.join("-").toLowerCase()
            : t._threadName.name.toLowerCase();
        return t.DiscussionName.toLowerCase().includes(searchValue) || threadName.includes(searchValue);
      });
    }
  },
  methods: {
    refresh(loaded, withLoading = true) {
      this.isLoading = true;
      Promise.all([
        this.$store.dispatch(types.GET_UNREAD_MESSAGES, this.$store.getters.userUniqueId),
        this.$store.dispatch(types.GET_THREADS, this.$store.getters.userUniqueId)
      ]).then(() => {
        this.isLoading = false;
        if (loaded) loaded("done");
      });
    },
    onSearch() {},
    onSubmit() {}
  }
};
</script>
