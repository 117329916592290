<template>
  <div class="select">
    <div
      class="form w-10/12 mx-auto relative mt-4"
      v-if="search">
      <div class="input-container flex flex-row items-center justify-center">
        <i
          @click="forceUpdate"
          class="fa fa-search absolute left-0 text-20 cursor-pointer"></i>
        <div class="w-full">
          <input
            @input="input"
            autofocus
            :value="searchText"
            :placeholder="STRINGS.SEARCH_TEXT"
            type="text"
            ref="_search"
            name="search" />
        </div>
        <slot name="search-btn" />
      </div>
    </div>
    <div
      class="select-list mt-6"
      ref="list">
      <div
        class="select-list--item"
        v-for="option in filteredSortedOptions"
        :key="option.value"
        :class="{ active: isOptionActive(option.value) }"
        @click="click(option.value)">
        {{ option.label }}
      </div>
      <h5
        class="m-4 text-center"
        v-if="filteredSortedOptions.length === 0">
        {{ STRINGS.NO_RESULTS_FOUND }}
      </h5>
    </div>
  </div>
</template>

<script>
import { isEmpty } from "globals/utils/utils";

export default {
  name: "vue-select",
  props: {
    options: {
      type: Array,
      required: true
    },
    search: {
      type: Boolean,
      default: true
    },
    value: {
      type: [String, Number, Array],
      default: () => {
        if (this.multiple) return [];
        return "";
      }
    },
    multiple: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      searchText: ""
    };
  },
  watch: {
    options() {
      //select first if options change
      this.$nextTick(() => {
        if (this.valueArr.length === 0) {
          if (this.options.length > 0) {
            let firstOption = this.options[0];
            if (typeof firstOption.value !== "undefined") {
              this.click(firstOption.value);
            }
          }
        }
      });
    }
  },

  async mounted() {
    //scroll to active element
    await this.$nextTick();
    if (this.valueArr.length > 0) {
      let _activeElement = this.$refs.list.querySelector(".select-list--item.active");
      //make sure we got a ref
      if (_activeElement) {
        //dont scroll if its the first item
        if (!this.isFirstOptionSelected) {
          if (typeof _activeElement.scrollIntoView === "function") {
            _activeElement.scrollIntoView();
          }
        }
      }
    }

    if (this.search) {
      this.$refs._search.focus();
    }
  },
  computed: {
    valueArr() {
      if (Array.isArray(this.value)) {
        return this.value;
      }
      return [this.value];
    },
    isFirstOptionSelected() {
      if (this.valueArr.length > 0) {
        if (this.options.length > 0) {
          let firstOption = this.options[0];
          if (typeof firstOption.value !== "undefined") {
            return this.valueArr.includes(firstOption.value);
          }
        }
      }
      return false;
    },
    filteredSortedOptions() {
      //May want to remove accents --__--
      let searchTextClean = this.searchText.toLowerCase();
      let options = this.options.filter((option) => {
        let searchValue = option.label.toLowerCase();
        return searchValue.includes(searchTextClean);
      });
      return options;
    }
  },
  methods: {
    forceUpdate() {
      this.$forceUpdate();
    },
    input(e) {
      this.searchText = e.target.value;
    },
    isOptionActive(value) {
      if (this.valueArr.length === 0) return false;
      if (isEmpty(value)) return false;
      //make sure they are both string, just to compare
      return this.valueArr.includes(value.toString());
    },
    click(value) {
      let payload = value;
      if (this.multiple) {
        let valueArr = [...this.valueArr];
        //to string just to compare
        let alreadySelectedIdx = valueArr.findIndex((v) => v.toString() === value.toString());
        if (alreadySelectedIdx === -1) {
          valueArr.push(payload);
        } else {
          valueArr.splice(alreadySelectedIdx, 1);
        }

        payload = valueArr;
      }
      this.$emit("select", payload);
    }
  }
};
</script>

<style lang="scss" scoped>
.select {
  .select-list {
    border-top: 1px solid theme("colors.dark");

    &--item {
      cursor: pointer;
      padding: 0.75rem;
      border-bottom: 1px solid theme("colors.dark");

      &.active {
        border-bottom: 1px solid theme("colors.green");
        background: theme("colors.green-light");
      }
    }
  }

  .form {
    input {
      padding-left: 30px;
    }
  }
}
</style>
