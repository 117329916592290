import Vue from "vue";
import VueRouter from "vue-router";
import Login from "mobile/views/Login";
import Timer from "mobile/views/Timer";
import Chat from "mobile/views/Chat";
import Messages from "mobile/views/Messages";
import CreateThread from "mobile/views/CreateThread";
import { ROUTES_DEFINITION } from "mobile/router/routes";
import Index from "mobile/views/Index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: ROUTES_DEFINITION.INDEX,
    name: "index",
    component: Index
  },
  {
    path: ROUTES_DEFINITION.LOGIN,
    name: "login",
    component: Login
  },
  {
    path: ROUTES_DEFINITION.TIMER,
    name: "timer",
    component: Timer,
    meta: {
      requireAuth: true
    }
  },
  {
    path: ROUTES_DEFINITION.CHAT,
    name: "chat",
    component: Chat,
    meta: {
      requireAuth: true
    }
  },
  {
    path: ROUTES_DEFINITION.CREATE_THREAD,
    name: "create-thread",
    component: CreateThread,
    meta: {
      requireAuth: true
    }
  },
  {
    path: ROUTES_DEFINITION.MESSAGES,
    name: "messages",
    component: Messages,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "*",
    redirect: ROUTES_DEFINITION.LOGIN
  }
];

const router = new VueRouter({
  mode: "history",
  base: "/app/",
  routes
});

router.afterEach((to, from) => {
  document.body.classList.remove(`p-${from.name}`);
  document.body.classList.add(`p-${to.name}`);
});

window.vueRouter = router;

export default router;
