<template>
  <section
    id="chat"
    class="chat">
    <div class="chat__header">
      <div class="container">
        <router-link :to="ROUTES.MESSAGES">
          <p class="font-bold">
            <i
              class="fa fa-arrow-left mr-2"
              aria-hidden="true"></i
            >Messages
          </p>
        </router-link>
      </div>
      <div class="container">
        <div class="chat__header__row">
          <div>
            <h3
              class="text-left text-light"
              v-if="thread">
              {{ thread.subject }}
            </h3>
            <h2
              class="chat-users"
              v-if="threadName && threadName.type !== THREAD_NAME_TYPES.RECIPIENTS">
              {{ threadName.name }}
            </h2>
            <h2
              class="chat-users"
              v-if="threadName && threadName.type === THREAD_NAME_TYPES.RECIPIENTS"
              :class="{ 'hide-all': !showAllRecipients }">
              {{ threadName.name.join(" - ") }}
            </h2>
          </div>
          <div
            class="flex flex-col justify-center items-center"
            v-if="threadName && threadName.type === THREAD_NAME_TYPES.RECIPIENTS && threadName.name.length > 1">
            <button
              class="chat__header__btn"
              type="button"
              @click.prevent="toggleMoreRecipients">
              ...
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="chat__messages"
      ref="chatArea">
      <pull-to
        ref="pullTo"
        :bottom-load-method="loadData"
        :top-config="TOP_DEFAULT_CONFIG"
        :bottom-config="TOP_DEFAULT_CONFIG">
        <message
          v-for="message in messages"
          :key="message.IdAuto"
          :message="message"
          :recipient-id="recipientId"></message>
      </pull-to>
    </div>

    <div class="form chat__form">
      <textarea
        v-model="message"
        placeholder="Message"></textarea>
      <button
        type="button"
        class="btn"
        @click.prevent="onSubmit">
        {{ STRINGS.SEND_MESSAGE }}
      </button>
    </div>
  </section>
</template>

<script>
import deleteIcon from "globals/assets/img/delete-cross.png";
import Vue from "vue";
import * as types from "mobile/store/types";
import { isEmpty, titleCase } from "globals/utils/utils";
import Message from "mobile/components/chat/Message";
import PullTo from "vue-pull-to";
import { ROUTES } from "mobile/router/routes";

export default {
  name: "Chat",
  components: { Message, PullTo },
  data() {
    return {
      deleteIcon: deleteIcon,
      message: "",
      showAllRecipients: false,

      makeScroll: false, //make sure that if people scroll

      errors: {}
    };
  },
  computed: {
    thread() {
      return this.$store.state.data.threads.find((t) => t.IdAuto === this.$route.params.id) || null;
    },
    threadName() {
      return this.$store.getters.getThreadName(this.thread);
    },
    messages() {
      if (!this.thread) return [];
      return this.$store.state.data.messagesByThreadId[this.thread.IdAuto] || [];
    },
    lastMessage() {
      if (!this.thread) return null;
      if (this.messages.length === 0) {
        return this.thread.LastMessage;
      }
      return this.messages[this.messages.length - 1];
    },
    recipients() {
      if (!this.thread) return [];
      return this.thread.Recipients.split(",");
    },
    recipientId() {
      //only for 1-1 message
      if (this.recipients.length === 0) return null;
      if (this.recipients.length > 1) return null;
      return this.recipients[0];
    }
  },
  created() {
    this.TOP_DEFAULT_CONFIG = {
      pullText: "Reload", // The text is displayed when you pull down
      triggerText: "Reloading", // The text that appears when the trigger distance is pulled down
      loadingText: "Loading...", // The text in the load
      doneText: "", // Load the finished text
      failText: "Error" // Load failed text
    };

    if (!this.$store.state.data.parameters.mobileChatActive) {
      this.$router.replace(ROUTES.INDEX);
      return;
    }

    this.loadData().then(() => {
      this.scrollDown(false);
      this.startPolling();
    });
  },
  mounted() {
    this.scrollDown(false);
  },
  beforeDestroy() {
    this.stopPolling();
  },
  methods: {
    startPolling() {
      this.pollingId = setInterval(() => {
        let lastMessageId = this.lastMessage ? this.lastMessage.IdAuto : null;
        this.loadData().then(() => {
          //if new message scroll to bottom
          this.$nextTick(() => {
            if (lastMessageId && this.lastMessage) {
              if (lastMessageId !== this.lastMessage) {
                this.scrollDown(true);
              }
            }
          });
        });
      }, 10000); //10 SEC
    },
    stopPolling() {
      clearInterval(this.pollingId);
    },
    scrollDown(smooth = false) {
      //TODO: if the person scroll dont auto scroll since thats anoying
      this.$nextTick(() => {
        if (!this.$refs.pullTo) return;
        let _scrollContainer = this.$refs.pullTo.$refs["scroll-container"];
        _scrollContainer.scrollTo({
          left: 0,
          top: _scrollContainer.scrollHeight,
          behavior: smooth ? "smooth" : "auto"
        });
      });
    },
    loadData(loaded) {
      return this.$store
        .dispatch(types.GET_THREAD, {
          threadId: this.$route.params.id,
          userId: this.$store.getters.userUniqueId
        })
        .then((data) => {
          if (loaded) loaded("done");
          return data;
        });
    },
    toggleMoreRecipients() {
      this.showAllRecipients = !this.showAllRecipients;
    },
    onSubmit() {
      if (!this.thread) return;

      this.errors = {}; //reset
      if (isEmpty(this.message)) {
        this.errors = {
          message: "Veuillez entrer un message"
        };
        return;
      }
      this.$store
        .dispatch(types.CREATE_MESSAGE, {
          message: this.message,
          senderId: this.$store.getters.userUniqueId,
          threadId: this.thread.discussionID,
          lastMessageId: this.lastMessage ? this.lastMessage.IdAuto : null
        })
        .then(() => {
          this.scrollDown(true);
        });

      this.message = ""; //reset
    }
  }
};
</script>
