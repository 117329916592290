<template>
  <div class="install-pwa">
    <div
      class="gap"
      ref="gap"></div>
    <div
      ref="content"
      class="fixed left-0 right-0 bottom-0 bg-light"
      v-if="!isInstall">
      <div
        class="w-full h-full p-2"
        v-if="deferredPrompt && isAndroid">
        <p class="text-green text-center">{{ STRINGS.INSTALL_APP }}</p>
        <button
          class="btn mt-2 mb-0 mx-auto"
          type="button"
          @click.prevent="promptInstall">
          {{ STRINGS.INSTALL_APP_BTN }}
        </button>
      </div>
      <div
        class="w-full h-full p-2"
        v-else-if="isIOS && !isFromHomeScreen">
        <p class="text-green text-center">{{ STRINGS.INSTALL_APP }}</p>
        <p class="mt-2 text-green text-12 text-center flex flex-row items-center justify-center">
          {{ STRINGS.INSTALL_APP_BTN_IOS_BEFORE }}
          <img
            class="w-3 inline mx-1"
            :src="iOSIconAction" />
          {{ STRINGS.INSTALL_APP_BTN_IOS_AFTER }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import iOSIconAction from "globals/assets/img/ios_action_icon.png";
import { isIOS, isAndroid } from "globals/utils/utils";

export default {
  name: "install-pwa",
  data() {
    return {
      isInstall: false,
      deferredPrompt: null,
      isIOS: isIOS(),
      isAndroid: isAndroid(),
      isFromHomeScreen: false,
      iOSIconAction: iOSIconAction
    };
  },
  watch: {
    $data() {
      this.setHeight();
    }
  },
  methods: {
    promptInstall() {
      // Show the prompt:
      this.deferredPrompt.prompt();

      // Wait for the user to respond to the prompt:
      this.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          this.isInstall = true;
          // User accepted the install prompt
        } else {
          this.isInstall = false;
        }

        this.deferredPrompt = null;

        this.setHeight();
      });
    },
    setHeight() {
      this.$nextTick(() => {
        if (this.$refs.content && this.$refs.gap) {
          this.$refs.gap.style.height = this.$refs.content.clientHeight + "px";
        }
      });
    },
    beforeInstallPrompt(event) {
      // Prevent Chrome >=67 from automatically showing the prompt:
      event.preventDefault();

      // Stash the event so it can be triggered later:
      this.deferredPrompt = event;

      this.setHeight();
    }
  },
  created() {
    this.beforeInstallPrompt = this.beforeInstallPrompt.bind(this);
    window.addEventListener("beforeinstallprompt", this.beforeInstallPrompt);

    if (window.navigator !== "undefined") {
      if (window.navigator.standalone !== "undefined") {
        if (window.navigator.standalone) {
          this.isFromHomeScreen = true;

          this.setHeight();
        }
      }
    }
  },
  mounted() {
    this.setHeight();
  },
  beforeDestroy() {
    window.removeEventListener("beforeinstallprompt", this.beforeInstallPrompt);
  }
};
</script>
