import { isEmpty } from "globals/utils/utils";
import LocalForage from "./local";

export const userMobileDeviceStore = LocalForage.createInstance({ name: "user-mobile-device-id" });
window.userMobileDeviceStore = userMobileDeviceStore;
window.__userMobileDeviceId__ = null;

export const createUserMobileDeviceId = (companyId, nip) => {
  return window.btoa(`${companyId}-${nip}`);
};

//can only set once expect if we force it
export const saveMobileDeviceId = (userMobileId) => {
  window.__userMobileDeviceId__ = userMobileId; //set it local
  userMobileDeviceStore.setItem("user-mobile-device-id", userMobileId); //set it permanent
};

//get promise
export const getUserMobileDeviceIdFromStorage = () => {
  return userMobileDeviceStore.getItem("user-mobile-device-id");
};

//set it when loaded so we get locally
getUserMobileDeviceIdFromStorage()
  .then((deviceId) => {
    window.__userMobileDeviceId__ = deviceId;
  })
  .catch((err) => {});
