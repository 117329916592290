import { isEmpty } from "globals/utils/utils";
import { STRINGS } from "globals/utils/constant";

export const isEmail = (text) => {
  //stupid test because email testing in front-end is never accurate
  return text.includes("@") && text.includes(".");
};

export const validateData = (data, expected) => {
  let errors = {};

  if (expected.includes("nip")) {
    if (isEmpty(data.nip)) {
      errors["nip"] = STRINGS(window.vueStore.state.auth.lang || "fr").VALIDATION_ERROR_NIP;
    }
  }

  if (expected.includes("companyId")) {
    if (isEmpty(data.companyId)) {
      errors["companyId"] = STRINGS(window.vueStore.state.auth.lang || "fr").VALIDATION_ERROR_COMPANY;
    }
    if (data.companyId.length < 6) {
      errors["companyId"] = STRINGS(window.vueStore.state.auth.lang || "fr").VALIDATION_ERROR_LENGTH_COMPANY;
    }
  }

  return errors;
};

export const getValidationErrorMessage = (name) => {
  //fake validation, just to get error message
  let errors = validateData({ [name]: "" }, [name]);
  return errors[name];
};
