<template>
  <section
    id="company-login"
    class="h-full relative px-2">
    <LoadingContainer :is-loading="isLoading">
      <h1 class="text-light text-center py-6">{{ STRINGS.ENTER_COMPANY_CODE }}</h1>
      <CompanyLoginForm @submit="login" />
    </LoadingContainer>
  </section>
</template>

<script>
import CompanyLoginForm from "ipad/components/forms/CompanyLoginForm";
import * as types from "ipad/store/types";
import LoadingContainer from "globals/components/LoadingContainer";
import { ROUTES } from "ipad/router/routes";
import { tryAsyncFunc } from "globals/utils/utils";

export default {
  name: "CompanyLogin",
  components: { LoadingContainer, CompanyLoginForm },
  data() {
    return {
      isLoading: false
    };
  },
  created() {
    this.$store.dispatch(types.COMPANY_LOGOUT);
    this.$store.dispatch(types.USER_LOGOUT);
  },
  methods: {
    async login(validData) {
      this.isLoading = true;
      const [res, err] = await tryAsyncFunc(this.$store.dispatch)(types.COMPANY_LOGIN, validData);
      this.isLoading = false;

      if (err) {
        return;
      }

      //use .replace so we cant go back with the button on the browser or on android with the arrow
      this.$router.replace(ROUTES.INDEX);
    }
  }
};
</script>
