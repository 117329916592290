<template>
  <form
    method="POST"
    @submit.prevent="submit"
    @keydown.enter.prevent="submit"
    accept-charset="UTF-8"
    class="flex flex-col justify-between h-full">
    <div
      class="input-container -big"
      :class="{ focus: currentlySelectedInput === 'companyId' }">
      <label
        class="text-light"
        :for="`company-id-${_uid}`"
        >{{ STRINGS.LOGIN_COMPANY_CODE }}</label
      >
      <input
        v-model="companyId"
        :id="`company-id-${_uid}`"
        name="companyId"
        type="text"
        ref="companyIdInput"
        @focus="onInputSelect($event, 'companyId')"
        class="-light" />
      <p
        class="error"
        v-if="validationErrors.companyId">
        {{ validationErrors.companyId }}
      </p>
    </div>

    <div class="mt-4">
      <number-keyboard
        :showSubmit="true"
        :submitText="submitText"
        @submit="submit"
        @key="keyPress"
        @erase="erase"></number-keyboard>
    </div>
  </form>
</template>
<script>
import { validateData } from "globals/utils/validation";
import { isEmpty } from "globals/utils/utils";
import NumberKeyboard from "globals/components/NumberKeyboard";

export default {
  name: "login-form",
  components: { NumberKeyboard },
  data() {
    return {
      currentlySelectedInput: "companyId",

      companyId: "",
      validationErrors: {}
    };
  },
  created() {
    if (!isEmpty(this.$store.state.auth.companyId)) {
      this.companyId = this.$store.state.auth.companyId;
    }
  },
  mounted() {
    this.$refs.companyIdInput.focus();
  },
  computed: {
    submitText() {
      return this.STRINGS.PAD_BTN_SUBMIT;
    }
  },
  methods: {
    keyPress(key) {
      if (this[this.currentlySelectedInput] !== "undefined") {
        this[this.currentlySelectedInput] += key;
      }
    },
    erase() {
      if (this[this.currentlySelectedInput] !== "undefined") {
        if (this[this.currentlySelectedInput].length > 0) {
          this[this.currentlySelectedInput] = this[this.currentlySelectedInput].slice(
            0,
            this[this.currentlySelectedInput].length - 1
          );
        }
      }
    },
    onInputSelect(e, dataName) {
      if (this.$store.state.general.isTouchDevice) {
        if (e && e.target) e.target.blur(); //remove keyboard in ios and android
      }
      this.currentlySelectedInput = dataName;
    },
    submit() {
      this.validationErrors = {};
      let data = {
        companyId: this.companyId
      };

      this.validationErrors = validateData(data, ["companyId"]);

      //must be valid data
      if (!isEmpty(this.validationErrors)) {
        return;
      }

      this.$emit("submit", data);
    }
  }
};
</script>
}}
