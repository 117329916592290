<template>
  <div
    v-if="showPopup"
    class="is-offline">
    <p class="text-center">{{ STRINGS.IPAD_NO_INTERNET }}</p>
  </div>
</template>

<script>
export default {
  name: "ipad-is-offline",
  computed: {
    showPopup() {
      return !this.$store.state.general.isOnline;
    }
  }
};
</script>

<style lang="scss">
.is-offline {
  @apply w-full bg-red p-2;

  p {
    @apply text-light;
  }
}
</style>
