import Vue from "vue";
import * as types from "ipad/store/types";
import * as globalTypes from "globals/store/types";
import DataAPI from "globals/services/DataAPI";
import merge from "lodash-es/merge";
import PunchAPI from "globals/services/PunchAPI";
import { isEmpty } from "globals/utils/utils";
import getLocation from "globals/utils/geolocation";
import moment from "moment";
import cloneDeep from "lodash-es/cloneDeep";
import EventBus, { EVENTS } from "globals/utils/eventbus";
import { STRINGS } from "globals/utils/constant";
import { getDataStore } from "globals/store/local";
import ChatAPI from "globals/services/ChatAPI";
import { GET_SITE_PATH } from "globals/store/types";
import { GET_ALL_COMPANY_DATA } from "ipad/store/types";
// Static

// initial state, with craft backend or empty
const initialState = {
  punchsMadeOffline: [],
  lastFetchDate: "",
  lastCompanyFetchDate: "",

  //last register location
  location: {
    lat: "",
    lng: "",
    accuracy: ""
  },

  sitePath: "/",

  unreadMessages: [],
  departments: [],
  allDepartments: [],
  allDepartmentGroups: [],
  departmentGroups: [],
  punchs: [],
  lastPunch: null,
  allBranchs: [],
  branchs: [],
  companies: [],
  allCompanies: [],
  allProjects: [],
  projects: [],
  allActivities: [],
  activities: [],
  allOperations: [],
  operations: [],
  employees: [],
  parameters: {},

  version: "1.0.10" //if we change any data inside our state, we need to change the version
};

// getters, make function easy to access by vue
const getters = {};

// actions
const actions = {
  [globalTypes.ADD_TO_OFFLINE_PUNCH](store, punchs) {
    store.commit(globalTypes.ADD_TO_OFFLINE_PUNCH, punchs);
  },
  [globalTypes.MAKE_ALL_OFFLINE_PUNCHS](store) {
    EventBus.$emit(EVENTS.APP_LOADER, true);
    let punchs = cloneDeep(store.state.punchsMadeOffline);

    punchs.sort((a, b) => {
      if (typeof a.CheckTime === "undefined") return 0;
      if (typeof b.CheckTime === "undefined") return 0;

      let aMoment = moment(a.CheckTime);
      let bMoment = moment(b.CheckTime);
      return aMoment.isBefore(bMoment) ? -1 : 1;
    });

    //reset to empty array
    store.commit(globalTypes.MAKE_ALL_OFFLINE_PUNCHS);

    return PunchAPI.ipadOfflinePunches(punchs)
      .then(() => {
        EventBus.$emit(EVENTS.APP_LOADER, false);
      })
      .catch((err) => {
        EventBus.$emit(EVENTS.APP_LOADER, false);
        if (err && err.Message) {
          store.commit(types.ERROR_MESSAGE, err.Message);
        }
      });
  },
  [types.LOAD_LOCATION](store) {
    return getLocation()
      .then((data) => {
        store.commit(types.LOAD_LOCATION, data);
        return data;
      })
      .catch((err) => {
        //reset to 0
        store.commit(types.LOAD_LOCATION, {
          latitude: "0",
          longitude: "0",
          accuracy: "-1"
        });
        return err;
      });
  },
  [types.LOAD_USER_DATA](store, nip) {
    return new Promise((resolve, reject) => {
      store
        .dispatch(types.GET_USER_PARAMETERS, nip)
        .then((user) => {
          if (!user) {
            store.commit(types.ERROR_MESSAGE, STRINGS(store.rootState.auth.lang).ERROR_VERIFY);
            reject();
            return; //stop calling all the parameters
          }

          store.commit(types.LAST_FETCH_DATE); //save the date we fetch those data

          //start with empty array
          let promises = [
            //global
            store.dispatch(globalTypes.GET_SITE_PATH),
            store.dispatch(types.GET_EMPLOYEES) //fetch them even higher
          ];

          //fetch depends on params values
          if (store.rootState.data.parameters.DepartmentsUsed) {
            promises.push(store.dispatch(types.GET_DEPARTMENTS));
          }
          if (store.rootState.data.parameters.useDepartmentGroups) {
            promises.push(store.dispatch(types.GET_DEPARTMENT_GROUPS));
          }
          if (store.rootState.data.parameters.ActivitiesUsed) {
            promises.push(store.dispatch(types.GET_ACTIVITIES));
          }
          if (store.rootState.data.parameters.BranchUsed) {
            promises.push(store.dispatch(types.GET_BRANCHS));
          }
          if (store.rootState.data.parameters.OperationsUsed) {
            promises.push(store.dispatch(types.GET_OPERATIONS));
          }
          if (store.rootState.data.parameters.ProjectUsed) {
            promises.push(store.dispatch(types.GET_PROJECTS, store.getters.userId));
          } else {
            promises.push(store.dispatch(types.GET_DEFAULT_DEPARTMENTS));
          }

          if (store.rootState.data.parameters.mobilePunchUseCompanies) {
            promises.push(store.dispatch(types.GET_COMPANIES));
          }

          Promise.all(promises)
            .then((allData) => {
              resolve(allData);
            })
            .catch(() => {
              reject();
            });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [types.CREATE_PROJECT](store, number) {
    return DataAPI.createProject(number).then(() => {
      return store.dispatch(types.GET_PROJECTS, store.getters.userId);
    });
  },
  [types.GET_PROJECTS](store, userId) {
    return DataAPI.getProjects(userId).then((projects) => {
      store.commit(types.GET_PROJECTS, projects);
      return projects;
    });
  },
  [types.GET_COMPANIES](store) {
    return DataAPI.getCompanies().then((companies) => {
      store.commit(types.GET_COMPANIES, companies);
      return companies;
    });
  },
  [types.GET_DEPARTMENTS](store) {
    return DataAPI.getDepartments().then((departments) => {
      store.commit(types.GET_DEPARTMENTS, departments);
    });
  },
  [types.GET_DEPARTMENTS_BY_GROUP](store, groupId) {
    return DataAPI.getDepartmentsByGroup(groupId).then((departments) => {
      store.commit(types.GET_DEPARTMENTS_BY_GROUP, departments);
    });
  },
  [types.GET_DEPARTMENT_GROUPS](store) {
    return DataAPI.getDepartmentGroups().then((departmentGroups) => {
      store.commit(types.GET_DEPARTMENT_GROUPS, departmentGroups);
    });
  },
  [types.GET_DEFAULT_DEPARTMENTS](store, userId) {
    return DataAPI.getDefaultDepartments(userId).then((departments) => {
      store.commit(types.GET_DEPARTMENTS, departments);
    });
  },
  [types.GET_ACTIVITIES](store, payload) {
    return DataAPI.getActivities().then((activities) => {
      if (!activities) return [];
      store.commit(types.GET_ACTIVITIES, activities);
      return activities;
    });
  },
  [types.GET_BRANCHS](store, payload) {
    return DataAPI.getBranchs().then((branchs) => {
      if (!branchs) return [];
      store.commit(types.GET_BRANCHS, branchs);
      return branchs;
    });
  },
  [types.GET_OPERATIONS](store) {
    return DataAPI.getOperations().then((operations) => {
      if (!operations) return [];
      store.commit(types.GET_OPERATIONS, operations);
      return operations;
    });
  },
  [types.GET_UNREAD_MESSAGES](store, senderId) {
    return ChatAPI.getAllUnreadMessages(senderId).then((messages) => {
      store.commit(types.GET_UNREAD_MESSAGES, messages || []);
      return messages;
    });
  },
  [types.GET_EMPLOYEES](store, payload) {
    return DataAPI.getEmployees().then((employees) => {
      if (!employees) return [];
      store.commit(types.GET_EMPLOYEES, employees);
      return employees;
    });
  },
  [globalTypes.PUNCH](store, punches) {
    if (!store.rootState.general.isOnline) {
      punches.forEach((punchData) => {
        store.commit(globalTypes.PUNCH, punchData);
        store.commit(types.SAVE_LAST_PUNCH, punchData);
      });
      return null;
    }

    return PunchAPI.punch(punches)
      .then((data) => {
        punches.forEach((punchData) => {
          store.commit(globalTypes.PUNCH, punchData);
          store.commit(types.SAVE_LAST_PUNCH, punchData);
        });
        return data;
      })
      .catch((err) => {
        if (err && err.Message) {
          store.commit(types.ERROR_MESSAGE, err.Message);
        }
      });
  },
  [globalTypes.GET_SITE_PATH](store) {
    return DataAPI.getSitePath().then((url) => {
      store.commit(globalTypes.GET_SITE_PATH, url);
    });
  },
  [types.GET_ALL_COMPANY_DATA](store, payload) {
    return new Promise((resolve, reject) => {
      //start with empty array
      let promises = [
        DataAPI.getDepartments(),
        DataAPI.getDepartmentGroups(),
        DataAPI.getActivities(),
        DataAPI.getBranchs(),
        DataAPI.getOperations(),
        DataAPI.getActiveProjects(),
        DataAPI.getCompanies(),

        DataAPI.getParams()
      ];

      store.commit(types.LAST_COMPANY_FETCH_DATE); //save the date we fetch those data

      Promise.allSettled(promises)
        .then((allData) => {
          store.commit(types.GET_ALL_COMPANY_DATA, {
            departments: allData[0].value,
            departmentGroups: allData[1].value,
            activities: allData[2].value,
            branchs: allData[3].value,
            operations: allData[4].value,
            projects: allData[5].value,
            companies: allData[6].value,

            parameters: allData[7].value
          });

          resolve(allData);
        })
        .catch(() => {
          reject();
        });
    });
  }
};

// mutations
const mutations = {
  [globalTypes.GET_SITE_PATH](state, url) {
    state.sitePath = url;
  },
  [globalTypes.ADD_TO_OFFLINE_PUNCH](state, punchs) {
    punchs.forEach((punch) => {
      state.punchsMadeOffline.push(punch);
    });
  },
  [types.LAST_FETCH_DATE](state) {
    state.lastFetchDate = moment().format();
  },
  [types.LAST_COMPANY_FETCH_DATE](state) {
    state.lastCompanyFetchDate = moment().format();
  },
  [globalTypes.MAKE_ALL_OFFLINE_PUNCHS](state) {
    state.punchsMadeOffline = [];
  },
  [types.LOAD_LOCATION](state, data) {
    if (isEmpty(data)) return;
    state.location = {
      lat: data.latitude,
      lng: data.longitude,
      accuracy: data.accuracy
    };
  },
  [types.GET_BRANCHS](state, branchs) {
    state.branchs = branchs;
  },
  [types.GET_ACTIVITIES](state, activities) {
    state.activities = activities;
  },
  [types.GET_PROJECTS](state, projects) {
    state.projects = projects;
  },
  [types.GET_COMPANIES](state, companies) {
    state.companies = companies;
  },
  [types.GET_OPERATIONS](state, operations) {
    state.operations = operations;
  },
  [types.GET_DEPARTMENT_GROUPS](state, departmentGroups) {
    departmentGroups.forEach((d) => {
      let alreadyThere = state.departmentGroups.find((d_) => d_.ID === d.ID);
      if (!alreadyThere) {
        state.departmentGroups.push(d);
      }
    });
  },
  [types.GET_DEPARTMENTS_BY_GROUP](state, departments) {
    departments.forEach((d) => {
      let alreadyThere = state.departments.find((d_) => d_.ID === d.ID);
      if (!alreadyThere) {
        state.departments.push(d);
      }
    });
  },
  [types.GET_DEPARTMENTS](state, departments) {
    departments.forEach((d) => {
      let alreadyThere = state.departments.find((d_) => d_.ID === d.ID);
      if (!alreadyThere) {
        state.departments.push(d);
      }
    });
  },
  [globalTypes.PUNCH](state, punch) {
    state.punchs.push(punch);
  },
  [types.SAVE_LAST_PUNCH](state, punch) {
    state.lastPunch = punch;
  },
  [types.GET_UNREAD_MESSAGES](state, messages) {
    state.unreadMessages = messages;
  },
  [types.GET_EMPLOYEES](state, employees) {
    state.employees = employees;
  },
  [types.GET_ALL_COMPANY_DATA](state, allData) {
    state.parameters = allData.parameters;

    state.allDepartments = allData.departments;
    state.allDepartmentGroups = allData.departmentGroups;
    state.allBranchs = allData.branchs;
    state.allProjects = allData.projects;
    state.allActivities = allData.activities;
    state.allOperations = allData.operations;
    state.allCompanies = allData.companies;
  },
  [types.RESTORE_MUTATION](state, payload) {
    if (!payload) {
      return;
    }
    if (!payload.data) {
      return;
    }

    let excludes = []; //put var to exclude here

    //set state
    //only if same version
    if (payload.data.version && payload.data.version === initialState.version) {
      Object.keys(payload.data).forEach((key) => {
        if (!isEmpty(payload.data[key])) {
          if (!excludes.includes(key)) {
            state[key] = JSON.parse(JSON.stringify(payload.data[key]));
          }
        }
      });
    }
  },
  [types.USER_LOGOUT](state) {
    //clear all data
    //only delete those keys when we logout
    let includes = ["sitePath", "lastPunch", "punchs"];

    Object.keys(state).forEach((key) => {
      if (includes.includes(key)) {
        try {
          state[key] = JSON.parse(JSON.stringify(initialState[key]));
        } catch (e) {}
      }
    });
  },
  [types.COMPANY_LOGOUT](state) {
    let excludes = []; //delete everything

    Object.keys(state).forEach((key) => {
      if (!excludes.includes(key)) {
        try {
          state[key] = JSON.parse(JSON.stringify(initialState[key]));
        } catch (e) {}
      }
    });

    //delete vuex persist setup
    getDataStore().clear();
  }
};

export default {
  namespaced: false,
  state: merge({}, initialState), //make sure its not the same object
  getters,
  actions,
  mutations
};
