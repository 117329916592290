<template>
  <div class="form flex flex-col justify-between h-full chat-form">
    <div class="mt-6">
      <h2 class="text-light pb-3">
        {{ STRINGS.CREATE_THREAD }}
      </h2>
      <div class="input-container -select">
        <label :for="`employees-${_uid}`">
          {{ selectStrings.employee }}
        </label>
        <div
          class="select-container"
          @click.stop.prevent="openModal('employees')">
          <span class="search-icon">
            <span class="search-icon-item">
              <i class="fa fa-search"></i>
            </span>
          </span>
          <ModalSelect
            v-if="modals.employees"
            @close="closeModal"
            @select="select"
            :value="employeeIds"
            :options="employeesOptions"
            name="employees"
            valueName="employeeIds"
            :multiple="true"></ModalSelect>

          <div class="fake-select">
            <span v-if="employeesSelected.length > 0">
              <span
                v-for="employee in employeesSelected"
                :key="employee.IdAuto">
                {{ employee.nomEmploye | titleCase }}
              </span>
            </span>
            <span v-else>{{ STRINGS.PLEASE_SELECT_OPTION }}</span>
          </div>
        </div>
        <p
          class="error"
          v-if="validationErrors.employeeIds">
          {{ validationErrors.employeeIds }}
        </p>
      </div>
      <div
        class="input-container -select"
        v-if="parameters.ProjectUsed">
        <label :for="`projects-${_uid}`">
          {{ selectStrings.project }}
        </label>
        <div
          class="select-container"
          @click.stop.prevent="openModal('projects')">
          <span class="search-icon">
            <a
              class="search-icon-item"
              @click.stop.prevent="projectInfoClick"
              target="_blank"
              v-if="showMoreProjectInfoURL">
              <i class="fa fa-question"></i>
            </a>
            <span
              v-else
              class="search-icon-item">
              <i class="fa fa-search"></i>
            </span>
          </span>
          <ModalSelect
            v-if="modals.projects"
            @close="closeModal"
            @select="select"
            :value="projectId"
            :options="projectsOptions"
            name="projects"
            valueName="projectId"></ModalSelect>

          <div class="fake-select">
            <span v-if="projectSelected">{{ projectSelected.Name }}</span>
            <span v-else>{{ STRINGS.PLEASE_SELECT_OPTION }}</span>
          </div>
        </div>
        <p
          class="error"
          v-if="validationErrors.projectId">
          {{ validationErrors.projectId }}
        </p>
      </div>
      <div
        class="input-container -select"
        v-if="parameters.DepartmentsUsed">
        <label :for="`departments-${_uid}`">
          {{ selectStrings.department }}
        </label>
        <div
          class="select-container"
          @click.stop.prevent="openModal('departments')">
          <span class="search-icon">
            <span class="search-icon-item">
              <i class="fa fa-search"></i>
            </span>
          </span>
          <ModalSelect
            v-if="modals.departments"
            @close="closeModal"
            @select="select"
            :value="departmentId"
            :options="departmentsOptions"
            name="departments"
            valueName="departmentId"></ModalSelect>

          <div class="fake-select">
            <span v-if="departmentSelected">{{ departmentSelected.Name }}</span>
            <span v-else>{{ STRINGS.PLEASE_SELECT_OPTION }}</span>
          </div>
        </div>
        <p
          class="error"
          v-if="validationErrors.departmentId">
          {{ validationErrors.departmentId }}
        </p>
      </div>

      <div class="input-container mt-6">
        <label for="subject">
          {{ STRINGS.YOUR_SUBJECT }}
        </label>
        <input
          id="subject"
          type="text"
          name="subject"
          v-model="subject"
          class="" />
        <p
          class="error"
          v-if="validationErrors.subject">
          {{ validationErrors.subject }}
        </p>
      </div>

      <div class="input-container mt-3">
        <label for="newMessage">
          {{ STRINGS.YOUR_MESSAGE }}
        </label>
        <textarea
          name="newMessage"
          v-model="newMessage"
          rows="4"></textarea>
        <p
          class="error"
          v-if="validationErrors.newMessage">
          {{ validationErrors.newMessage }}
        </p>
      </div>
      <p
        class="error my-3"
        v-if="validationErrors.global">
        {{ validationErrors.global }}
      </p>

      <div class="flex flex-row justify-end items-center mt-6">
        <button
          class="btn"
          @click.prevent="send">
          {{ STRINGS.SEND_MESSAGE }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import formMixin from "globals/config/formMixin";
import ModalSelect from "globals/components/modals/ModalSelect";
import { isEmpty } from "globals/utils/utils";

export default {
  name: "chat-form",
  components: { ModalSelect },
  mixins: [formMixin],

  data() {
    return {
      newMessage: "",
      subject: ""
    };
  },
  methods: {
    send() {
      this.validationErrors = {};
      if (isEmpty(this.newMessage)) {
        this.validationErrors.newMessage = this.STRINGS.VALIDATION_ERROR_NEW_MESSAGE;
      }

      //check if we select nothing
      if (
        isEmpty(this.projectId) &&
        isEmpty(this.branchId) &&
        isEmpty(this.departmentId) &&
        this.employeeIds.length === 0
      ) {
        this.validationErrors.global = this.STRINGS.VALIDATION_ERROR_GLOBAL;
      }

      if (!isEmpty(this.validationErrors)) {
        return;
      }

      this.$emit("submit", {
        message: this.newMessage,
        receiversId: this.employeeIds,
        threadName: !isEmpty(this.subject) ? this.subject : "DEFAULT",
        projectId: !isEmpty(this.projectId) ? this.projectId.toString() : null,
        branchId: !isEmpty(this.branchId) ? this.branchId.toString() : null,
        departmentId: !isEmpty(this.departmentId) ? this.departmentId.toString() : null
      });
    }
  }
};
</script>
