import EventBus, { EVENTS } from "globals/utils/eventbus";
import * as types from "globals/store/types";
import { APP_TYPES } from "globals/utils/constant";

let tryingToRelogin = false;

export const relogin = (companyId, nip, onSuccess, onError) => {
  if (tryingToRelogin) {
    return;
  }

  tryingToRelogin = true;
  EventBus.$emit(EVENTS.APP_LOADER, true);

  window.vueStore
    .dispatch(types.LOGIN, {
      companyId: companyId,
      nip: nip
    })
    .then((result) => {
      tryingToRelogin = false;
      EventBus.$emit(EVENTS.APP_LOADER, false);
      if (typeof onSuccess === "function") {
        onSuccess();
      }
    })
    .catch((err) => {
      tryingToRelogin = false;
      EventBus.$emit(EVENTS.APP_LOADER, false);

      //go to login if relogin dont work
      if (window.appType === APP_TYPES.MOBILE) {
        window.vueRouter.replace("/login").catch(() => {});
      } else if (window.appType === APP_TYPES.IPAD) {
        window.vueRouter.replace("/").catch(() => {});
      }

      if (typeof onError === "function") {
        onError();
      }
    });
};
