<template>
  <router-link
    :to="ROUTES.CHAT(thread.IdAuto)"
    class="thread">
    <div class="profile-pic">
      <inline-icon
        name="users"
        v-if="isMultiUser" />
      <inline-icon
        name="user"
        v-else />
    </div>

    <div class="thread__text">
      <div class="thread__text__header">
        <p
          class="text-sm font-bold pr-2 text-base"
          v-if="threadName && threadName.type !== THREAD_NAME_TYPES.RECIPIENTS">
          {{ threadName.name }}
        </p>
        <div class="flex flex-row justify-center items-start">
          <p
            class="text-sm font-bold"
            :class="{ 'overflow-hidden h-6': !showAllRecipients }"
            v-if="threadName && threadName.type === THREAD_NAME_TYPES.RECIPIENTS">
            {{ threadName.name.join(" - ") }}
          </p>
          <div
            class="flex flex-col justify-center items-center mr-4"
            v-if="threadName && threadName.type === THREAD_NAME_TYPES.RECIPIENTS && threadName.name.length > 1">
            <button
              class="chat__header__btn -2"
              type="button"
              @click.prevent="toggleMoreRecipients">
              ...
            </button>
          </div>
        </div>
        <p class="date">{{ date }}</p>
      </div>
      <div class="thread__text__body">
        <div class="thread__text__body__text">
          <p
            class="text-body"
            v-if="thread.LastMessage">
            {{ thread.LastMessage.MessageContent }}
          </p>
        </div>
        <p
          class="thread__text__body__unread"
          v-if="hasNotification">
          1
        </p>
      </div>
    </div>
  </router-link>
</template>

<script>
import InlineIcon from "globals/components/InlineIcon";
import { THREAD_NAME_TYPES } from "globals/utils/constant";
import moment from "moment";

export default {
  name: "thread",
  components: { InlineIcon },
  props: {
    thread: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showAllRecipients: false
    };
  },
  methods: {
    toggleMoreRecipients() {
      this.showAllRecipients = !this.showAllRecipients;
    }
  },
  computed: {
    threadName() {
      return this.$store.getters.getThreadName(this.thread);
    },
    isMultiUser() {
      if (!this.threadName) return false;
      if (this.threadName.type === THREAD_NAME_TYPES.RECIPIENTS && this.threadName.name.length < 2) {
        return false;
      }

      return true;
    },
    hasNotification() {
      return this.thread.HasNotification !== "0";
    },
    date() {
      return moment(this.thread.LastMessageOn).format("L");
    }
  }
};
</script>
