<template>
  <button
    type="button"
    @click="click"
    class="btn btn--timer"
    :class="{ disabled: disabled }">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "btn-effect",
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    triggerActiveEffect(node) {
      //fake hover because on mobile it get stuck
      if (node) {
        node.classList.add("active");
        setTimeout(() => {
          node.classList.remove("active");
        }, 100);
      }
    },
    click(e) {
      if (this.disabled) return;
      this.$emit("click", e);

      if (e.target) {
        this.triggerActiveEffect(e.target);
      }
    }
  }
};
</script>
