<template>
  <div
    class="modal"
    ref="modal"
    @click.stop=""
    :class="`modal-${name} modal-type-${type}`">
    <!-- Stop propagation -->
    <div class="modal--inner-container">
      <div
        class="modal--header"
        v-if="canClose">
        <button
          type="button"
          class="btn--close"
          @click.prevent="_onClose">
          <i class="fa fa-close text-large"></i>
        </button>
      </div>
      <div class="modal--content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { isDescendant } from "globals/utils/utils";

export default {
  name: "modal",
  props: {
    canClose: {
      type: Boolean,
      default: true
    },
    name: {
      type: String,
      required: false,
      default: "default"
    },
    type: {
      type: String,
      default: "normal"
    }
  },
  mounted() {
    this.parentNode = this.$refs.modal.parentNode;
    window.document.body.className += " modal-open";
    window.document.body.appendChild(this.$refs.modal);

    window.document.addEventListener("keyup", this.onKeyEscape);

    //if we dont do that, the initial open click will be trigger --__--
    setTimeout(() => {
      window.document.body.addEventListener("click", this.onBodyClick);
    }, 250);
  },
  beforeDestroy() {
    if (this.parentNode) {
      this.parentNode.appendChild(this.$refs.modal);
    }
    window.document.body.className = window.document.body.className.replace("modal-open", "");
    window.document.removeEventListener("keyup", this.onKeyEscape);
    window.document.body.removeEventListener("click", this.onBodyClick);
  },
  methods: {
    onKeyEscape(e) {
      if (e.keyCode === 27) {
        this._onClose();
      }
    },
    onBodyClick(e) {
      if (!isDescendant(this.$refs.modal, e.target)) {
        this._onClose();
      }
    },
    _onClose() {
      if (this.canClose) {
        this.$emit("close");
      }
    }
  }
};
</script>

<style lang="scss">
.modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  min-width: 100px;
  max-width: 650px;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  z-index: -1;
  border: none;

  @screen md {
    width: 66%;
    max-width: 750px;
  }

  &:hover {
    border: none;
  }

  &--inner-container {
    position: relative;
    max-height: 80vh;
    width: 100%;
  }

  &--header {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate3d(50%, -50%, 0);
    z-index: 9;

    .btn-close {
      opacity: 1;
    }
  }

  &--content {
    position: relative;
    background: theme("colors.light");
    padding: 1.5rem;
    border-radius: 5px;
    // box-shadow: $box-shadow-4;
    max-height: 80vh;
    overflow-y: auto;

    @screen md {
      padding: 3rem;
    }
  }

  &-type-large {
    max-width: 850px;
    width: 85%;

    @screen md {
      width: 80%;
    }
  }

  &-select {
    min-height: 75vh;

    .modal--inner-container {
      min-height: 75vh;
    }

    .modal--content {
      padding: 0;
      min-height: 75vh;
    }
  }
}

body.modal-open {
  position: relative;
  overflow: hidden;

  * {
    pointer-events: none;
  }

  &:after {
    content: "";
    display: block;
    width: 120%;
    height: 120%;
    top: 0;
    left: -10%;
    bottom: 0;
    right: 10%;
    z-index: 99;
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    filter: blur(1);
  }

  > .modal {
    * {
      pointer-events: auto;
    }

    pointer-events: auto;
    z-index: 999999;
    opacity: 1;
  }
}
</style>
