<template>
  <section
    id="login"
    class="h-full relative px-2">
    <LoadingContainer :is-loading="isLoading">
      <LoginForm @submit="login" />
    </LoadingContainer>
  </section>
</template>

<script>
import LoginForm from "mobile/components/forms/LoginForm";
import * as types from "mobile/store/types";
import LoadingContainer from "globals/components/LoadingContainer";
import { ROUTES } from "mobile/router/routes";

export default {
  name: "login",
  components: { LoadingContainer, LoginForm },
  data() {
    return {
      isLoading: false
    };
  },
  created() {
    this.$store.dispatch(types.LOGOUT);
  },
  methods: {
    login(validData) {
      this.isLoading = true;
      this.$store
        .dispatch(types.LOGIN, validData)
        .then(() => {
          this.isLoading = false;
          //use .replace so we cant go back with the button on the browser or on android with the arrow
          this.$router.replace(ROUTES.INDEX);
        })
        .catch((err) => {
          this.isLoading = false;
        });
    }
  }
};
</script>
