import EventBus, { EVENTS } from "globals/utils/eventbus";
import debounce from "lodash-es/debounce";
import * as globalTypes from "globals/store/types";

let _isOnline = true;
if (typeof window.navigator !== "undefined") {
  if (typeof window.navigator.onLine !== "undefined") {
    _isOnline = window.navigator.onLine;
  }
}

const isOnlineMixin = {
  data() {
    return {
      IS_ONLINE: JSON.stringify(JSON.parse(_isOnline)) //dont ref it
    };
  },
  created() {
    this.$store.commit(globalTypes.SET_IS_ONLINE, this.IS_ONLINE); //init

    this.changeIsOnline = this.changeIsOnline.bind(this);
    this.changeIsOnlineON = this.changeIsOnlineON.bind(this);
    this.changeIsOnlineOFF = this.changeIsOnlineOFF.bind(this);

    //those function actually get call alot, like 30 times, so we use debounce
    window.addEventListener("online", this.changeIsOnlineON);
    window.addEventListener("offline", this.changeIsOnlineOFF);
  },
  beforeDestroy() {
    window.removeEventListener("online", this.changeIsOnlineON);
    window.removeEventListener("offline", this.changeIsOnlineOFF);
  },
  methods: {
    //the function is important so debounce know its the same this
    changeIsOnlineON: debounce(
      function () {
        this.changeIsOnline(true);
      },
      100,
      { leading: true, trailing: false }
    ),
    //the function is important so debounce know its the same this
    changeIsOnlineOFF: debounce(
      function () {
        this.changeIsOnline(false);
      },
      100,
      { leading: true, trailing: false }
    ),

    changeIsOnline(isOnline) {
      this.IS_ONLINE = isOnline;
      this.$store.commit(globalTypes.SET_IS_ONLINE, isOnline);

      EventBus.$emit(EVENTS.IS_ONLINE, isOnline);
    }
  }
};

export default isOnlineMixin;
