<template>
  <div
    class="modal-flash"
    :class="{ '-show': isVisible }"
    @click.prevent="hideFlash">
    <div
      ref="message"
      class="modal-flash__message"
      :class="`bg-${color_}`">
      <p
        :class="`text-${textColor_}`"
        v-html="message_"></p>
    </div>
  </div>
</template>

<script>
import EventBus, { EVENTS } from "globals/utils/eventbus";
import { STRINGS } from "globals/utils/constant";

const DEFAULT_PROPS = {
  message: STRINGS(window.vueStore ? window.vueStore.state.auth.lang : "fr").FLASH_DEFAULT,
  color: "green",
  textColor: "light",
  time: 3000 //3seconds
};

export default {
  name: "modal-flash",
  created() {
    this.showFlash = this.showFlash.bind(this);
    this.hideFlash = this.hideFlash.bind(this);
    EventBus.$on(EVENTS.SHOW_MODAL_FLASH, this.showFlash);
    EventBus.$on(EVENTS.HIDE_MODAL_FLASH, this.hideFlash);

    this.id = -1;
  },
  data() {
    return {
      isVisible: false,
      message_: DEFAULT_PROPS.message,
      color_: DEFAULT_PROPS.color,
      textColor_: DEFAULT_PROPS.textColor,
      time_: DEFAULT_PROPS.time
    };
  },
  mounted() {},
  beforeDestroy() {
    EventBus.$off(EVENTS.SHOW_MODAL_FLASH, this.showFlash);
    EventBus.$off(EVENTS.HIDE_MODAL_FLASH, this.hideFlash);
  },
  props: {
    message: {
      type: String,
      default: DEFAULT_PROPS.message
    },
    color: {
      type: String,
      default: DEFAULT_PROPS.color
    },
    textColor: {
      type: String,
      default: DEFAULT_PROPS.textColor
    },
    time: {
      //in MS
      type: Number,
      default: DEFAULT_PROPS.time
    }
  },
  methods: {
    showFlash(props = {}) {
      if (this.isVisible) {
        //hide the flash and call again
        this.hideFlash();
        //make sure the data is change, so we escape a infinite loop
        this.isVisible = false;

        this.showFlash(props);
        return;
      }
      clearTimeout(this.id);

      //set props
      this.message_ = props.message ? props.message : DEFAULT_PROPS.message;
      this.color_ = props.color ? props.color : DEFAULT_PROPS.color;
      this.textColor_ = props.textColor ? props.textColor : DEFAULT_PROPS.textColor;
      this.time_ = props.time ? props.time : DEFAULT_PROPS.time;

      this.isVisible = true;

      //call hide in
      this.id = setTimeout(this.hideFlash, this.time_);
    },
    hideFlash() {
      this.isVisible = false;
    }
  }
};
</script>

<style lang="scss">
.modal-flash {
  @apply fixed inset-0 overflow-hidden flex flex-col justify-center items-center;
  display: none;
  opacity: 0;
  transition: 100ms all ease;
  background-color: rgba(0, 0, 0, 0.5);

  &.-show {
    display: flex;
    opacity: 1;
  }

  &__message {
    max-width: 90vw;
    padding: 1rem 2rem;

    @screen md {
      max-width: 30vw;
    }

    p {
      @apply p-4 text-center;
    }
  }
}
</style>
