<template>
  <div
    class="keyboard flex flex-col items-center"
    :class="{
      'pointer-events-none opacity-25': disabled
    }">
    <fragment
      v-for="(pad, idx) in pads"
      :key="idx">
      <div class="red-gradient-border"></div>
      <div class="flex flex-row flex-nowrap justify-between items-center max-w-xs w-full">
        <BtnEffect
          class="w-1/3"
          @click.prevent="onKeyPress($event, number)"
          v-for="number in pad"
          :key="number"
          >{{ number }}
        </BtnEffect>
      </div>
    </fragment>
    <div class="red-gradient-border"></div>
    <BtnEffect
      v-if="showSubmit"
      class="w-full"
      @click="submit($event)">
      {{ submitText }}
    </BtnEffect>
  </div>
</template>

<script>
import Fragment from "globals/components/Fragment";
import BtnEffect from "globals/components/BtnEffect";

export default {
  name: "NumberKeyboard",
  components: { BtnEffect, Fragment },
  props: {
    showSubmit: {
      type: Boolean,
      default: false
    },
    submitText: {
      type: String,
      default: "Enter"
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    pads() {
      return [
        ["7", "8", "9"],
        ["4", "5", "6"],
        ["1", "2", "3"],
        ["-", "0", this.STRINGS.PAD_BTN_EFF]
      ];
    }
  },
  methods: {
    triggerActiveEffect(node) {
      if (node) {
        node.classList.add("active");
        setTimeout(() => {
          node.classList.remove("active");
        }, 150);
      }
    },
    onKeyPress(e, key) {
      if (this.disabled) return;

      if (key === this.STRINGS.PAD_BTN_EFF) {
        this.$emit("erase");
        return;
      }

      this.$emit("key", key);

      if (e.target) {
        this.triggerActiveEffect(e.target);
      }
    },
    submit(e) {
      if (this.disabled) return;

      this.$emit("submit");

      if (e.target) {
        this.triggerActiveEffect(e.target);
      }
    }
  }
};
</script>

<style lang="scss">
.keyboard {
  user-select: none;
}
</style>
