import axios, { setHeaders } from "globals/services/api";

const PunchAPI = {
  punch(punches) {
    return axios({
      method: "post",
      url: "/Punch",
      data: { Punches: punches },
      headers: {
        "Content-Type": "application/json"
      }
    }).then((data) => {
      return data;
    });
  },
  ipadOfflinePunches(punches) {
    return axios({
      method: "post",
      url: "/OfflinePunch",
      data: { Punches: punches },
      headers: {
        "Content-Type": "application/json"
      }
    }).then((data) => {
      return data;
    });
  }
};

export default PunchAPI;
