import flatten from "lodash-es/flatten";
import startCase from "lodash-es/startCase";
import toLower from "lodash-es/toLower";
import moment from "moment";

export const isEmpty = (value) =>
  typeof value === "undefined" ||
  value === undefined ||
  value === null ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0);

export const titleCase = (str) => {
  return startCase(toLower(str));
};

export const capitalize = (str) => {
  if (!str) return "";
  return str.replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase();
  });
};

export const isDescendant = (parent, child) => {
  if (isEmpty(parent)) {
    return false;
  }

  if (isEmpty(child)) {
    return false;
  }

  //make array
  let parents = parent;
  if (typeof parent.length === "undefined") {
    parents = [parent];
  }

  parents = Array.prototype.slice.call(parents);

  var node = child.parentNode;
  while (node != null) {
    if (parents.includes(node)) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
};

export const isIOS = () => {
  if (typeof window.navigator === "undefined") return false;
  return (
    (/iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)) &&
    !window.MSStream
  );
};

export const isAndroid = () => {
  if (typeof window.navigator === "undefined") return false;
  return /(android)/i.test(navigator.userAgent);
};

export const isTouchDevice = () => {
  var prefixes = " -webkit- -moz- -o- -ms- ".split(" ");
  var mq = function (query) {
    return window.matchMedia(query).matches;
  };

  if ("ontouchstart" in window || (window.DocumentTouch && document instanceof window.DocumentTouch)) {
    return true;
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  var query = ["(", prefixes.join("touch-enabled),("), "heartz", ")"].join("");
  return mq(query);
};

export const sortAlphabetic = (property) => (a, b) => {
  //make sure property exist
  if (typeof a[property] === "undefined") return 0;
  if (typeof b[property] === "undefined") return 0;

  let aLabel = a[property].toLowerCase();
  let bLabel = b[property].toLowerCase();

  if (aLabel < bLabel) return -1;
  if (aLabel > bLabel) return 1;

  return 0;
};

export const getMessagesFromDiscussionTree = (discussionTree) => {
  const traverse = (message) =>
    (message.replies.messages.length ? [] : [[message]]).concat(
      ...message.replies.messages.map((child) => traverse(child).map((arr) => [message].concat(arr)))
    );

  return flatten(traverse(discussionTree)).sort((a, b) => (moment(a.TimeSent).isBefore(moment(b.TimeSent)) ? -1 : 1));
};

export const tryAsyncFunc =
  (func) =>
  async (...params) => {
    let result = null;
    let error = null;
    try {
      result = await func(...params);
    } catch (e) {
      error = e;
      // eslint-disable-next-line no-console
      console.error(e);
    }

    if (typeof result === "undefined") {
      error = new Error("No return value of func:");
      // eslint-disable-next-line no-console
      console.log(error, func.toString());
      if (typeof window.Sentry !== "undefined") {
        //if sentry dont submit, dont make this func crash
        try {
          window.Sentry.captureException(error);
        } catch (e) {}
      }
    }

    return [result, error];
  };
