<template>
  <section
    id="create-thread"
    class="h-full">
    <LoadingContainer :is-loading="isLoading">
      <ChatForm @submit="send" />
    </LoadingContainer>
  </section>
</template>
<script>
import * as types from "mobile/store/types";
import ChatForm from "mobile/components/forms/ChatForm";
import LoadingContainer from "globals/components/LoadingContainer";
import { ROUTES } from "mobile/router/routes";
import sortBy from "lodash-es/sortBy";
import isEqual from "lodash-es/isEqual";

export default {
  name: "create-thread",
  components: { LoadingContainer, ChatForm },
  data() {
    return {
      isLoading: false
    };
  },
  created() {
    if (!this.$store.state.data.parameters.mobileChatActive) {
      this.$router.replace(ROUTES.INDEX);
      return;
    }
    this.$store.dispatch(types.GET_EMPLOYEES);
  },
  methods: {
    send({ message, receiversId, threadName, projectId, branchId, departmentId }) {
      let messageDataForCompare = {
        IdProject: projectId ? projectId.toString() : "0",
        IdEtablissement: branchId ? branchId.toString() : "0",
        IdDepartement: departmentId ? departmentId.toString() : "0",
        Recipients: receiversId,
        DiscussionName: threadName
      };
      let threadExist = this.$store.state.data.threads.find((thread) => {
        let threadRecipients = thread.Recipients.split(",");
        let includeAllRecipients = isEqual(sortBy(threadRecipients), sortBy(messageDataForCompare.Recipients));
        return (
          thread.IdProject === messageDataForCompare.IdProject &&
          thread.IdEtablissement === messageDataForCompare.IdEtablissement &&
          thread.IdDepartement === messageDataForCompare.IdDepartement &&
          includeAllRecipients
        );
      });

      //dont create, just go to this thread
      if (threadExist) {
        this.$router.push(ROUTES.CHAT(threadExist.IdAuto));
        return;
      }

      // console.log("DATA", data)
      this.isLoading = true;
      this.$store
        .dispatch(types.CREATE_THREAD, {
          message,
          receiversId,
          threadName,
          projectId,
          branchId,
          departmentId,
          senderId: this.$store.getters.userUniqueId
        })
        .then((responseData) => {
          this.isLoading = false;
          this.$router.push(ROUTES.CHAT(responseData.IdAuto));
        });
    }
  }
};
</script>
