<template>
  <div v-if="lastPunch">
    <div class="red-gradient-border"></div>
    <div
      v-if="lastPunch.CheckType === PUNCH_TYPES.IN_LUNCH"
      class="yellow-gradient p-2">
      <h3 class="text-light text-center">{{ STRINGS.STATE_IN_LUNCH }}</h3>
    </div>
    <div
      v-else-if="lastPunch.CheckType === PUNCH_TYPES.IN || lastPunch.CheckType === PUNCH_TYPES.OUT_LUNCH"
      class="green-gradient p-2">
      <h3 class="text-light text-center">{{ STRINGS.STATE_PUNCH }}</h3>
    </div>
    <div
      v-else
      class="red-gradient p-2">
      <h3 class="text-light text-center">{{ STRINGS.STATE_WAITING }}</h3>
    </div>
    <div class="red-gradient-border"></div>
  </div>
</template>

<script>
export default {
  name: "punch-status",
  computed: {
    lastPunch() {
      return this.$store.state.data.lastPunch;
    }
  }
};
</script>
