import LocalForage from "localforage";

export default LocalForage;

let dataStore = null;

export const initLocalForage = (name, storeName) => {
  // This must be called before any other calls to localForage are made:
  LocalForage.config({
    name: name,
    storeName: storeName,
    version: 1.0
  });

  dataStore = LocalForage.createInstance({ name: "data" });
  return dataStore;
};

export const getDataStore = () => {
  return dataStore;
};
