<template>
  <section
    id="timer"
    class="h-full px-2">
    <h1 class="text-center text-light my-4">{{ STRINGS.GREETING }} {{ $store.getters.fullName }}</h1>
    <PunchStatus></PunchStatus>
    <TimerForm
      class="mt-6"
      @submit="punch" />
  </section>
</template>

<script>
import TimerForm from "globals/components/forms/TimerForm";
import EventBus, { EVENTS } from "globals/utils/eventbus";
import * as types from "mobile/store/types";
import * as globalTypes from "globals/store/types";
import PunchStatus from "globals/components/PunchStatus";
import { isEmpty, tryAsyncFunc } from "globals/utils/utils";
import { ROUTES } from "mobile/router/routes";
import moment from "moment/moment";
import { ALWAYS_REFETCH_DATA_AFTER_HOURS, APP_TYPES } from "globals/utils/constant";

export default {
  name: "timer",
  components: { PunchStatus, TimerForm },
  created() {
    //return back, if not login
    if (!this.$store.getters.isLogin) {
      this.$router.replace(ROUTES.LOGIN);
      return;
    }
  },
  async mounted() {
    this.checkForRefreshData();

    //get location
    const [res, err] = await tryAsyncFunc(this.$store.dispatch)(types.LOAD_LOCATION);
    if (err) {
      let message = this.STRINGS.LOCATION_CANT_SAVED;
      if (this.parameters.GeoLocationUsedForced) {
        message += this.STRINGS.LOCATION_FORCED;
      }
      EventBus.$emit(EVENTS.SHOW_FLASH, {
        message: message,
        color: "red",
        textColor: "light"
      });
      return;
    }
  },
  computed: {
    parameters() {
      return this.$store.state.data.parameters;
    },
    lastPunch() {
      return this.$store.state.data.lastPunch;
    }
  },
  watch: {
    "$store.state.general.isOnline"(isOnline) {
      if (isOnline) {
        //get location again
        this.$store.dispatch(types.LOAD_LOCATION);
        this.checkForRefreshData();
      }
    }
  },
  methods: {
    async checkForRefreshData() {
      await this.$nextTick();

      const [res, err] = await tryAsyncFunc(this.$store.dispatch)(
        types.GET_USER_PARAMETERS,
        this.$store.getters.userNIP
      );

      let now = moment();
      let fetchDate = moment(this.$store.state.data.lastFetchDate);
      let maximumFetchDate = now.add(ALWAYS_REFETCH_DATA_AFTER_HOURS, "hours");
      if (fetchDate.isAfter(maximumFetchDate)) {
        this.refreshTimerData();
      }
    },
    async punch(punches) {
      EventBus.$emit(EVENTS.APP_LOADER, true);

      //always refetch location
      const [res, err] = await tryAsyncFunc(this.$store.dispatch)(types.LOAD_LOCATION);

      if (err) {
        EventBus.$emit(EVENTS.APP_LOADER, false);
        EventBus.$emit(EVENTS.SHOW_FLASH, {
          message: this.STRINGS.LOCATION_NEEDED,
          color: "yellow",
          textColor: "black"
        });
        return;
      }

      //make sure we got lat, only when request are made when online
      if (this.parameters.GeoLocationUsedForced) {
        if (isEmpty(this.$store.state.data.location.lat)) {
          EventBus.$emit(EVENTS.SHOW_FLASH, {
            message: this.STRINGS.LOCATION_NEEDED,
            color: "yellow",
            textColor: "black"
          });
          EventBus.$emit(EVENTS.APP_LOADER, false);

          return;
        }
      }

      //Put lat and lng
      punches.forEach((punchData) => {
        punchData.Latitude = this.$store.state.data.location.lat.toString();
        punchData.Longitude = this.$store.state.data.location.lng.toString();

        //add device token
        punchData.MobileDeviceId = window.__userMobileDeviceId__;
      });

      const [resPunch, errPunch] = await tryAsyncFunc(this.$store.dispatch)(globalTypes.PUNCH, punches);

      EventBus.$emit(EVENTS.APP_LOADER, false);
      EventBus.$emit(EVENTS.SHOW_FLASH, {
        message: this.STRINGS.PUNCH_SAVED,
        color: "green",
        textColor: "light"
      });
    }
  }
};
</script>
