<template>
  <header class="px-2 header bg-green">
    <div class="header--content">
      <div class="flex flex-row justify-between items-center">
        <router-link
          class="block"
          to="/">
          <img
            alt="Info Punch Logo"
            :src="logo"
            class="block h-6" />
        </router-link>
        <div
          v-if="$route.name !== 'company-login'"
          class="flex flex-row justify-start items-center">
          <div class="relative">
            <button
              type="button"
              @click.prevent="toggleUserDropdown"
              class="bg-grey rounded-full flex flex-col items-center justify-center h-6 w-6">
              <i class="fa fa-user"></i>
            </button>
            <div
              class="user-dropdown"
              @click.stop=""
              v-if="showUserDropdown">
              <button
                v-if="isUserLogin"
                type="button"
                class="btn--dropdown"
                @click.prevent="goToPunch">
                {{ STRINGS.MENU_TIMER }}
              </button>
              <a
                v-if="isUserLogin"
                class="btn--dropdown"
                target="_blank"
                @click.prevent="userInfoClick">
                {{ STRINGS.MENU_PROFILE }}
              </a>
              <button
                v-if="isUserLogin"
                type="button"
                class="btn--dropdown"
                @click.prevent="refreshUserDataBtn">
                {{ STRINGS.MENU_REFRESH }}
              </button>
              <button
                v-else
                type="button"
                class="btn--dropdown"
                @click.prevent="refreshCompanyDataBtn">
                {{ STRINGS.MENU_REFRESH }}
              </button>
              <button
                type="button"
                class="btn--dropdown"
                @click.prevent="logout">
                {{ STRINGS.MENU_LOGOUT }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal
      v-if="showOfflineModal"
      @close="showOfflineModal = false">
      <p>{{ STRINGS.NO_INTERNET_POPUP }}</p>
    </Modal>
  </header>
</template>

<script>
import logo from "globals/assets/img/logo-infopunch.png";
import { ROUTES } from "ipad/router/routes";
import Modal from "globals/components/modals/Modal";
import DataAPI from "globals/services/DataAPI";
import * as types from "ipad/store/types";

export default {
  name: "VueIpadHeader",
  components: { Modal },
  data() {
    return {
      logo,
      showUserDropdown: false,
      showOfflineModal: false,

      forceShowNotification: false
    };
  },
  beforeDestroy() {
    this.userDropdownOFF(); //make sure we remove listener
  },
  computed: {
    isUserLogin() {
      return this.$store.getters.isLogin;
    }
  },
  methods: {
    userDropdownON() {
      this.showUserDropdown = true;

      this.dropdownId = setTimeout(() => {
        document.body.addEventListener("click", this.userDropdownOFF);
      }, 400); //wait for the event to propagate before adding the listener
    },
    toggleUserDropdown(e) {
      e.stopPropagation();
      if (this.showUserDropdown) {
        this.userDropdownOFF();
      } else {
        this.userDropdownON();
      }
    },
    userDropdownOFF() {
      clearTimeout(this.dropdownId);
      this.showUserDropdown = false;

      document.body.removeEventListener("click", this.userDropdownOFF);
    },
    btnClick() {
      this.userDropdownOFF();
    },
    refreshUserDataBtn() {
      this.userDropdownOFF();

      //timer
      this.refreshTimerData();

      this.$store.dispatch(types.GET_ALL_COMPANY_DATA);

      //CHAT
      this.$store.dispatch(types.GET_EMPLOYEES);
    },
    refreshCompanyDataBtn() {
      this.userDropdownOFF();

      this.$store.dispatch(types.GET_ALL_COMPANY_DATA);
    },
    logout() {
      this.userDropdownOFF();
      //Make sure we keep all actions so we can send them when back online
      if (this.$store.state.data.punchsMadeOffline.length > 0) {
        this.showOfflineModal = true;
        return;
      }

      //logout user if connected
      if (this.isUserLogin) {
        this.$store.dispatch(types.USER_LOGOUT);
        return;
      }

      //logout company if no user
      this.$router.push(ROUTES.COMPANY_LOGIN).catch(() => {});
    },
    goToPunch() {
      this.userDropdownOFF();
      this.$router.push(ROUTES.INDEX).catch(() => {});
    },
    userInfoClick() {
      //so the event click dont go to the document
      this.userDropdownOFF();
      const url = `${this.$store.state.data.sitePath}consult-mobile.asp?QLangue=fr&pinOverride=${this.$store.getters.userNIP}&noEmployeOverride=${this.$store.getters.userId}`;

      //open new page
      open(url, "_blank");
    }
  }
};
</script>

<style lang="scss">
.header--content {
  @apply py-4;

  * {
    color: theme("colors.light");
  }
}

.user-dropdown {
  @apply absolute right-0 shadow-lg;
  top: 30px;
  z-index: 9;
}
</style>
