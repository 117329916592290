<template>
  <footer class="px-2">
    <div class="pt-6 pb-4">
      <p class="text-center">&copy; {{ STRINGS.COPYRIGHT }}</p>
    </div>
    <install-pwa />
    <flash />
    <modal-flash />
  </footer>
</template>
<script>
import InstallPwa from "globals/components/InstallPWA";
import Flash from "globals/components/Flash";
import ModalFlash from "globals/components/ModalFlash.vue";

export default {
  name: "VueIpadFooter",
  components: { ModalFlash, Flash, InstallPwa }
};
</script>
