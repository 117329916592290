import Vue from "vue";
import VueRouter from "vue-router";
import { ROUTES_DEFINITION } from "ipad/router/routes";
import Index from "ipad/views/Index";
import CompanyLogin from "ipad/views/CompanyLogin";

Vue.use(VueRouter);

const routes = [
  {
    path: ROUTES_DEFINITION.INDEX,
    name: "index",
    component: Index,
    meta: {
      requireAuth: true
    }
  },
  {
    path: ROUTES_DEFINITION.COMPANY_LOGIN,
    name: "company-login",
    component: CompanyLogin
  },
  {
    path: "*",
    redirect: ROUTES_DEFINITION.INDEX
  }
];

const router = new VueRouter({
  mode: "history",
  base: "/app/",
  routes
});

router.afterEach((to, from) => {
  document.body.classList.remove(`p-${from.name}`);
  document.body.classList.add(`p-${to.name}`);
});

window.vueRouter = router;

export default router;
