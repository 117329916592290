import Vue from "vue";
import { ROUTES } from "mobile/router/routes";
import { WORK_TYPES, PUNCH_TYPES, STRINGS, THREAD_NAME_TYPES } from "globals/utils/constant";
import EventBus, { EVENTS } from "globals/utils/eventbus";
import * as types from "mobile/store/types";
import { isEmpty, tryAsyncFunc } from "globals/utils/utils";
import DataAPI from "globals/services/DataAPI";

//global
Vue.mixin({
  data() {
    return {
      ROUTES,
      WORK_TYPES,
      PUNCH_TYPES,
      THREAD_NAME_TYPES
    };
  },
  computed: {
    STRINGS() {
      return STRINGS(this.$store.state.auth.lang || "fr");
    }
  },
  methods: {
    isEmpty(value) {
      return isEmpty(value);
    },
    async refreshTimerData() {
      //need to be login
      if (!this.$store.getters.isLogin) {
        return;
      }

      //cant refresh offline
      if (!this.$store.state.general.isOnline) {
        EventBus.$emit(EVENTS.SHOW_FLASH, {
          message: this.STRINGS.NEED_INTERNET,
          color: "yellow",
          textColor: "black"
        });
        return;
      }

      EventBus.$emit(EVENTS.APP_LOADER, true);
      const [res, err] = await tryAsyncFunc(this.$store.dispatch)(types.LOAD_USER_DATA, this.$store.getters.userNIP);
      EventBus.$emit(EVENTS.APP_LOADER, false);
    }
  }
});
