import * as types from "ipad/store/types";
import * as globalTypes from "globals/store/types";
import merge from "lodash-es/merge";
import EventBus, { EVENTS } from "globals/utils/eventbus";
import { isEmpty, isTouchDevice } from "globals/utils/utils";
// Static

// initial state, with craft backend or empty
const initialState = {
  message: "",
  ajaxError: {},
  isTouchDevice: isTouchDevice(),
  isOnline: true
};

// getters, make function easy to access by vue
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {
  [types.ERROR_MESSAGE](state, mes) {
    state.message = mes;

    if (!isEmpty(mes)) {
      EventBus.$emit(EVENTS.SHOW_FLASH, {
        message: mes,
        color: "red",
        textColor: "light"
      });
    } else {
      EventBus.$emit(EVENTS.HIDE_FLASH);
    }
  },
  [globalTypes.AJAX_ERROR](state, err) {
    state.ajaxError = err;
  },
  [globalTypes.SET_IS_ONLINE](state, isOnline) {
    state.isOnline = isOnline;
  },
  [types.ROUTE_CHANGED](state) {
    state.message = "";
    state.ajaxError = {};
  },
  [types.COMPANY_LOGOUT](state) {
    state.message = "";
    state.ajaxError = {};
  },
  [types.USER_LOGOUT](state) {
    state.message = "";
    state.ajaxError = {};
  }
};

export default {
  namespaced: false,
  state: merge({}, initialState), //make sure its not the same object
  getters,
  actions,
  mutations
};
