<template>
  <Modal
    @close="close"
    name="select">
    <VueSelect
      :value="value"
      @select="select"
      :options="options"
      :multiple="multiple">
      <template
        v-if="$slots['search-btn']"
        #search-btn>
        <slot name="search-btn" />
      </template>
    </VueSelect>
  </Modal>
</template>

<script>
import Modal from "globals/components/modals/Modal";
import VueSelect from "globals/components/VueSelect";
import { isEmpty } from "globals/utils/utils";

export default {
  name: "modal-select",
  components: { VueSelect, Modal },
  props: {
    valueName: {
      type: String,
      default: "",
      required: true
    },
    name: {
      type: String,
      default: "",
      required: true
    },
    value: {
      type: [String, Number, Array],
      default: () => {
        if (this.multiple) return [];
        return "";
      }
    },
    options: {
      type: Array,
      required: true
    },
    multiple: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  methods: {
    close() {
      let payload = {
        value: this.value,
        name: this.name,
        valueName: this.valueName
      };
      this.$emit("close", payload);
    },
    select(value) {
      let payload = {
        value: value,
        name: this.name,
        valueName: this.valueName
      };

      this.$emit("select", payload);
      this.$nextTick(() => {
        if (!this.multiple) {
          this.close();
        }
      });
    }
  }
};
</script>
