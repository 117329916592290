export const ROUTES = {
  INDEX: "/",
  LOGIN: "/login",
  TIMER: "/timer",
  CHAT: (id) => `/chat/${id}`,
  MESSAGES: "/messages",
  CREATE_THREAD: "/create-thread"
};

export const ROUTES_DEFINITION = {
  INDEX: "/",
  LOGIN: "/login",
  TIMER: "/timer",
  CHAT: "/chat/:id",
  MESSAGES: "/messages",
  CREATE_THREAD: "/create-thread"
};
