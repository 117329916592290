<template>
  <span class="inline-icon block">
    <svg
      v-if="name === 'user'"
      xmlns="http://www.w3.org/2000/svg"
      class="w-full h-auto"
      viewBox="0 0 20 20"
      fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
        clip-rule="evenodd" />
    </svg>
    <svg
      v-if="name === 'users'"
      xmlns="http://www.w3.org/2000/svg"
      class="w-full h-auto"
      viewBox="0 0 20 20"
      fill="currentColor">
      <path
        d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
    </svg>

    <svg
      v-if="name === 'message'"
      xmlns="http://www.w3.org/2000/svg"
      class="w-full h-auto"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
    </svg>

    <svg
      v-if="name === 'barcode'"
      class="w-full h-auto"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 425.23 340.66">
      <path
        d="M425.23,333.14c-3.13,6-8.19,7.61-14.72,7.52-19.64-.28-39.28-.08-58.92-.12-5.31,0-9.51-3-10.9-7.34s-.09-9.37,3.8-11.76a15.68,15.68,0,0,1,7.72-2.25c15.49-.21,31-.11,46.47-.11h5.11v-4.63c0-15.77,0-31.54,0-47.31,0-6.07,3.05-10.06,8.29-11.44,4.8-1.27,9.08.75,12.13,5.68a11.92,11.92,0,0,0,1,1.16Z"
        fill="currentColor" />
      <path
        d="M425.23,78.13c-2.64,4-5.74,7.45-11.09,7.23-6.09-.26-10.3-5.23-10.33-12.25-.05-15.49,0-31,0-46.48V21.58h-4.7q-22.81,0-45.64,0c-8.29,0-13.34-4.14-13.27-10.85S345.18.12,353.25.11C372.48.1,391.7.3,410.92,0c6.46-.09,11.23,1.8,14.31,7.52Z"
        fill="currentColor" />
      <path
        d="M148.82,170.3c0,30.44-.24,60.89.13,91.32.14,10.94-4.26,15.85-14.94,15.09-5.5-.39-11.06,0-16.59-.08-6.81-.08-11.08-4.35-11.08-11.22q0-95.06,0-190.11c0-7,4.3-11.22,11.44-11.27,6.09,0,12.17,0,18.26,0,9.06,0,12.77,3.7,12.77,12.89Q148.86,123.61,148.82,170.3Z"
        fill="currentColor" />
      <path
        d="M212.63,170.17q0-46.9,0-93.81c0-8.42,4-12.32,12.47-12.33,6.36,0,12.72-.06,19.09,0s10.54,4.13,10.89,10.57c0,1,0,1.93,0,2.9V263.06c0,1.24,0,2.49-.11,3.73a10.23,10.23,0,0,1-10.32,9.82c-6.91.12-13.83.07-20.75,0-7.08-.05-11.3-4.28-11.31-11.41Q212.61,217.7,212.63,170.17Z"
        fill="currentColor" />
      <path
        d="M361.44,170.33q0,46.29,0,92.57c0,10.35-3.37,13.74-13.59,13.74-5.81,0-11.62,0-17.43,0-7.16-.05-11.46-4.21-11.47-11.25q0-95.05,0-190.11c0-7,4.31-11.19,11.47-11.24,5.95,0,11.9,0,17.85,0,9.63,0,13.16,3.56,13.17,13.33Q361.46,123.85,361.44,170.33Z"
        fill="currentColor" />
      <path
        d="M276.41,170.46q0-46.31,0-92.61a24.44,24.44,0,0,1,.7-7c1.39-4.46,5.9-7.14,10.71-6.84a10.27,10.27,0,0,1,9.51,8.38,31.88,31.88,0,0,1,.44,5.78q0,92.19,0,184.39a29.25,29.25,0,0,1-.47,5.77,10.54,10.54,0,0,1-20.77-1.15c-.16-1.65-.14-3.32-.14-5Q276.4,216.36,276.41,170.46Z"
        fill="currentColor" />
      <path
        d="M85.18,170.48q0,45.89,0,91.78a32,32,0,0,1-.47,6.19,10.54,10.54,0,0,1-20.76-1.31c-.13-1.37-.13-2.76-.13-4.15q0-92.6,0-185.21a23.33,23.33,0,0,1,.74-7c1.41-4.46,5.93-7.1,10.74-6.78a10.26,10.26,0,0,1,9.44,8.46,36.46,36.46,0,0,1,.43,6.19Q85.2,124.59,85.18,170.48Z"
        fill="currentColor" />
      <path
        d="M170.1,170.34q0-46.93,0-93.86c0-8,4.3-12.7,11.12-12.44A10.29,10.29,0,0,1,191,72.15a31.92,31.92,0,0,1,.48,6.19q0,92,0,184a32.79,32.79,0,0,1-.4,5.78,10.52,10.52,0,0,1-20.81-1c-.24-3.58-.15-7.19-.15-10.79Q170.09,213.33,170.1,170.34Z"
        fill="currentColor" />
      <path
        d="M21.44,319.08H69.32c1.52,0,3.05,0,4.57,0,6.57.2,11.25,4.77,11.15,10.88-.09,6-4.67,10.47-11.05,10.55-8.58.12-17.16,0-25.74,0-12,0-24.08,0-36.13,0-7.87,0-12.09-4.22-12.09-12q0-30.72,0-61.46c0-6.92,4.46-11.84,10.6-11.84s10.73,5,10.77,11.83c.1,15.64,0,31.29,0,46.93Z"
        fill="currentColor" />
      <path
        d="M21.44,21.58c0,17.12,0,33.59,0,50.05a22.38,22.38,0,0,1-.79,6.54A10.58,10.58,0,0,1,9.66,85.32C4.41,84.68.12,80.6.1,75.48Q-.09,42.68.11,9.86A10,10,0,0,1,10.2.2Q42.57,0,75,.22A10.38,10.38,0,0,1,85,11.24a10.6,10.6,0,0,1-10.87,10.3c-15.92.11-31.83,0-47.75,0Z"
        fill="currentColor" />
    </svg>
  </span>
</template>

<script>
export default {
  name: "inline-icon",
  props: {
    name: String
  }
};
</script>
