<template>
  <div
    class="flash-message"
    :class="`bg-${color_}`">
    <p :class="`text-${textColor_}`">{{ message_ }}</p>
  </div>
</template>

<script>
import EventBus, { EVENTS } from "globals/utils/eventbus";
import { STRINGS } from "globals/utils/constant";
import gsap from "gsap";

const DEFAULT_PROPS = {
  message: STRINGS(window.vueStore ? window.vueStore.state.auth.lang : "fr").FLASH_DEFAULT,
  color: "green",
  textColor: "light",
  time: 5000 //5seconds
};

export default {
  name: "flash",
  created() {
    this.showFlash = this.showFlash.bind(this);
    this.hideFlash = this.hideFlash.bind(this);
    EventBus.$on(EVENTS.SHOW_FLASH, this.showFlash);
    EventBus.$on(EVENTS.HIDE_FLASH, this.hideFlash);

    this.id = -1;
  },
  data() {
    return {
      isVisible: false,
      message_: DEFAULT_PROPS.message,
      color_: DEFAULT_PROPS.color,
      textColor_: DEFAULT_PROPS.textColor,
      time_: DEFAULT_PROPS.time
    };
  },
  mounted() {
    gsap.set(this.$el, { height: 0 });
  },
  beforeDestroy() {
    EventBus.$off(EVENTS.SHOW_FLASH, this.showFlash);
    EventBus.$off(EVENTS.HIDE_FLASH, this.hideFlash);
  },
  props: {
    message: {
      type: String,
      default: DEFAULT_PROPS.message
    },
    color: {
      type: String,
      default: DEFAULT_PROPS.color
    },
    textColor: {
      type: String,
      default: DEFAULT_PROPS.textColor
    },
    time: {
      //in MS
      type: Number,
      default: DEFAULT_PROPS.time
    }
  },
  methods: {
    showFlash(props = {}) {
      if (this.isVisible) {
        //hide the flash and call again
        this.hideFlash().then(() => {
          //make sure the data is change, so we escape a infinite loop
          this.isVisible = false;

          this.showFlash(props);
        });
        return;
      }
      clearTimeout(this.id);

      //set props
      this.message_ = props.message ? props.message : DEFAULT_PROPS.message;
      this.color_ = props.color ? props.color : DEFAULT_PROPS.color;
      this.textColor_ = props.textColor ? props.textColor : DEFAULT_PROPS.textColor;
      this.time_ = props.time ? props.time : DEFAULT_PROPS.time;

      this.isVisible = true;
      gsap.set(this.$el, { height: "auto" });
      gsap.from(this.$el, { duration: 0.5, height: 0 });

      //call hide in
      this.id = setTimeout(this.hideFlash, this.time_);
    },
    hideFlash() {
      return new Promise((resolve, reject) => {
        gsap.to(this.$el, {
          duration: 0.5,
          height: 0,
          onComplete: () => {
            this.isVisible = false;
            this.$nextTick(() => {
              resolve();
            });
          }
        });
      });
    }
  }
};
</script>

<style lang="scss">
.flash-message {
  @apply w-full fixed bottom-0 left-0 right-0 overflow-hidden;
  z-index: 11;

  p {
    @apply p-4 text-center;
  }
}
</style>
