<template>
  <div class="loader"></div>
</template>

<script>
export default {
  name: "loader",
  components: {}
};
</script>
