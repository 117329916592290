import VuexPersistence from "vuex-persist";

export default function createPersistedState(options = {}) {
  return (store) => {
    const VuexForage = new VuexPersistence({
      ...options,
      asyncStorage: true,

      // Used to trigger `storageReady` event as soon as the state is loaded
      // from LocalForage:
      restoreState: (key, storage) => {
        return new Promise((resolve) => {
          storage.getItem(key).then((data) => {
            resolve(data);
          });
        });
      }
    });

    return VuexForage.plugin(store);
  };
}
