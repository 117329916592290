import { STRINGS } from "globals/utils/constant";

const getLocation = () => {
  return new Promise((resolve, reject) => {
    if (typeof window.navigator === "undefined") {
      let payload = {
        code: -1,
        message: STRINGS(window.vueStore.state.auth.lang || "fr").NO_LOCATION_SUPPORTED
      };

      reject(payload);
      return;
    }

    //TODO: watch for position changes
    window.navigator.geolocation.getCurrentPosition(
      (pos) => {
        const data = pos.coords;
        let payload = {
          accuracy: data.accuracy,
          altitude: data.altitude,
          altitudeAccuracy: data.altitudeAccuracy,
          heading: data.heading,
          latitude: data.latitude,
          longitude: data.longitude,
          speed: data.speed
        };

        resolve(payload);
      },
      (err) => {
        let payload = {
          code: "",
          message: ""
        };
        if (err) {
          payload.code = err.code;
          payload.message = err.message;
        }
        reject(payload);
      },
      {
        enableHighAccuracy: true,
        timeout: 20000,
        maximumAge: 0
      }
    );
  });
};

export default getLocation;
