<template>
  <div
    class="o-loading"
    :class="{ 'is-loading': isLoading }">
    <div class="o-loading--content">
      <slot></slot>
    </div>
    <transition name="anim-loader">
      <Loader v-if="isLoading"></Loader>
    </transition>
  </div>
</template>

<script>
import Loader from "globals/components/Loader";

export default {
  name: "loading-container",
  components: {
    Loader
  },
  props: {
    useIf: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  }
};
</script>
