export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const SAVE_LAST_PUNCH = "SAVE_LAST_PUNCH";
export const GET_PROJECTS = "GET_PROJECTS";

export const GET_COMPANIES = "GET_COMPANIES";

export const GET_PARAMETERS_LAST_UPDATE = "GET_PARAMETERS_LAST_UPDATE";
export const GET_ACTIVITIES = "GET_ACTIVITIES";
export const GET_OPERATIONS = "GET_OPERATIONS";
export const GET_PARAMETERS = "GET_PARAMETERS";
export const LOAD_USER_DATA = "LOAD_USER_DATA";
export const GET_USER_PARAMETERS = "GET_USER_PARAMETERS";
export const GET_DEFAULT_DEPARTMENTS = "GET_DEFAULT_DEPARTMENTS";
export const GET_DEPARTMENTS = "GET_DEPARTMENTS";
export const GET_DEPARTMENTS_BY_GROUP = "GET_DEPARTMENTS_BY_GROUP";
export const GET_DEPARTMENT_GROUPS = "GET_DEPARTMENT_GROUPS";
export const GET_BRANCHS = "GET_BRANCHS";
export const ROUTE_CHANGED = "ROUTE_CHANGED";
export const ERROR_MESSAGE = "ERROR_MESSAGE";
export const LOAD_LOCATION = "LOAD_LOCATION";
export const LAST_FETCH_DATE = "LAST_FETCH_DATE";
//VUEx persist
export const RESTORE_MUTATION = "RESTORE_MUTATION";

export const ADD_TO_OFFLINE_MESSAGE = "ADD_TO_OFFLINE_MESSAGE";
export const MAKE_ALL_OFFLINE_MESSAGES = "MAKE_ALL_OFFLINE_MESSAGES";

export const SEND_QUIZ = "SEND_QUIZ";
export const TOGGLE_QUIZ = "TOGGLE_QUIZ";
export const SET_SHOW_QUIZ = "SET_SHOW_QUIZ";

export const SET_LANG = "SET_LANG";

export const GET_MESSAGES = "GET_MESSAGES";
export const GET_THREAD = "GET_THREAD";
export const CREATE_MESSAGE = "CREATE_MESSAGE";
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const CREATE_THREAD = "CREATE_THREAD";
export const GET_THREADS = "GET_THREADS";
export const GET_EMPLOYEES = "GET_EMPLOYEES";
export const MESSAGE_WAS_VIEWED = "MESSAGE_WAS_VIEWED";

export const GET_UNREAD_MESSAGES = "GET_UNREAD_MESSAGES";
