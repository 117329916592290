<template>
  <div id="app">
    <loading-container
      :is-loading="!isReady"
      class="min-h-screen">
      <div
        class="w-full min-h-screen flex flex-col justify-center items-center"
        v-if="isReady">
        <ipad-is-offline class="w-full" />
        <vue-ipad-header class="w-full" />
        <div class="flex-1 w-full">
          <loading-container
            :is-loading="isAppLoading"
            class="w-full h-full">
            <router-view />
          </loading-container>
        </div>
        <vue-ipad-footer class="w-full" />
      </div>
    </loading-container>
  </div>
</template>

<script>
import * as types from "ipad/store/types";
import LoadingContainer from "globals/components/LoadingContainer";
import router from "mobile/router";
import IpadIsOffline from "ipad/components/IpadIsOffline.vue";
import { ROUTES } from "ipad/router/routes";
import EventBus, { EVENTS } from "globals/utils/eventbus";
import { isEmpty } from "globals/utils/utils";
import VueIpadHeader from "ipad/components/VueIpadHeader";
import VueIpadFooter from "ipad/components/VueIpadFooter";
import isOnlineMixin from "globals/config/isOnlineMixin";

export default {
  name: "IpadApp",
  components: { VueIpadFooter, VueIpadHeader, LoadingContainer, IpadIsOffline },

  mixins: [isOnlineMixin],
  data() {
    return {
      isAppLoading: false
    };
  },

  watch: {
    //check for restricted url without being connected
    isReady() {
      this.$nextTick(() => {
        if (this.$route.meta.requireAuth && this.isReady && isEmpty(this.$store.state.auth.companyId)) {
          this.$router.replace(ROUTES.COMPANY_LOGIN);
        }
      });
    },
    "$store.state.general.isOnline"(isOnline) {
      if (isOnline) {
        //get location again
        this.$store.dispatch(types.LOAD_LOCATION);
      }
    }
  },
  computed: {
    isReady() {
      return this.$store.state.auth.tokenChecked;
    }
  },
  /* beforeRouteEnter(to, from, next) {
     if (this.isReady && to.meta && to.meta.requireAuth && !window.vueStore.getters.isLogin) {
       console.log('skip')
       next(ROUTES_DEFINITION.LOGIN)
       return
     }
     next()
   }, */
  created() {
    EventBus.$on(EVENTS.APP_LOADER, this.setAppLoader);
  },
  beforeDestroy() {
    EventBus.$off(EVENTS.APP_LOADER, this.setAppLoader);
  },
  mounted() {
    if (this.$store.state.general.isTouchDevice) {
      document.body.classList.add("is-touch");
    }
  },
  methods: {
    setAppLoader(isLoading) {
      this.isAppLoading = isLoading;
    }
  }
};
</script>
